.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 8px;
  grid-column-gap: 16px;
}

.w-embed-youtubevideo {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/youtube-placeholder.2b05e7d68d.svg');
  background-size: cover;
  background-position: 50% 50%;
}

.w-embed-youtubevideo:empty {
  min-height: 75px;
  padding-bottom: 56.25%;
}

body {
  background-color: #001427;
  font-family: Karla, sans-serif;
  color: #001427;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: bold;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 27px;
}

.website-wrapper {
  position: relative;
}

.green-bold {
  color: #00b67a;
}

.basic-3-grid {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.trustpilot-link-white {
  top: 1px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
}

.trustpilot-link-white:hover {
  color: #00b67a;
}

.top-bar-link-wrapper {
  margin-left: 8px;
  padding: 6px 12px;
  border-radius: 3px;
  background-color: hsla(0, 0%, 100%, 0.04);
  text-decoration: none;
}

.top-bar-link-wrapper:hover {
  background-color: hsla(0, 0%, 100%, 0.08);
}

.relative-block {
  position: relative;
}

.relative-block.center-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nieuws-text-center {
  position: relative;
  top: 2px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.top-bar {
  padding: 12px 16px;
  background-color: #001427;
}

.trustpilot-logo {
  position: relative;
  top: -3px;
  width: 70px;
  margin-left: 3px;
}

.klantenservice-closed,
.klantenservice-open {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 8px;
  border-radius: 100%;
  background-color: #27c97a;
}
.klantenservice-closed{
    background-color: #c92727;
}

.white-semi-bold {
  color: #fff;
  font-weight: 600;
}

.top-bar-link-text {
  position: relative;
  top: 1px;
  display: inline-block;
  margin-left: 4px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
}

.top-bar-link-text._2 {
  font-size: 12px;
}

.final-footer-text {
  display: inline-block;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}

.final-footer-text._2 {
  color: #0c0f21;
}

.top-bar-link-icon {
  width: 19px;
  margin-right: 4px;
}

.container {
  position: relative;
  max-width: 1100px;
}

.menu-text {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.strike-span {
  text-decoration: line-through;
}

.menu-heading-item {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 29px;
  line-height: 30px;
  font-weight: 600;
}

.two-col-image-right {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.menu-link-item {
  display: block;
  margin-bottom: 9px;
  padding-left: 16px;
  background-image: url('/website/images/right-arrow.svg');
  background-position: 0% 50%;
  background-size: 7px;
  background-repeat: no-repeat;
  font-family: Poppins, sans-serif;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
}

.menu-link-item:hover {
  opacity: 1;
}

.nav-link-wrapper {
  padding: 14px 32px 14px 14px;
  float: none;
  color: #0c0f21;
  font-size: 13px;
  text-align: center;
  text-decoration: none;
}

.nav-link-wrapper.active,
.nav-link-block.active,
.nav-link-wrapper.active .nav-link-title,
.nav-link-block.active .nav-link-title{
  text-decoration: none;
}
.nav-link-wrapper.active,
.nav-link-block.active{
    background: rgba(0,0,0,0.04);
}
.nav-link-wrapper:hover,
.nav-link-block:hover{
    background: rgba(0,0,0,0.06);
}
.nav-link-wrapper .w-icon-dropdown-toggle{
    margin-right: 12px;
}
.nav-link-wrapper.w--current {
  color: #4f30f1;
}

.nav-link-wrapper.w--open {
  padding-bottom: 14px;
}

.logo {
  width: 140px;
  margin-top: 0px;
}

.two-grid-menu-copy {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1.25fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.large-menu-grid {
  -ms-grid-columns: 4fr 1fr;
  grid-template-columns: 4fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.block-overlay {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  padding: 38px 38px 18px 18px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.57)), to(rgba(83, 83, 83, 0)));
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.57), rgba(83, 83, 83, 0));
}

.two-grid-menu {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1.25fr;
  grid-template-columns: 1fr 1fr 1fr 1.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.white-menu-text {
  color: #fff;
  font-size: 16px;
}

.two-grid-menu-block {
  position: relative;
  padding: 24px 23px 48px 0px;
}

.right-divider-wrap {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1px;
  height: 100%;
  margin-right: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-list-assortiment.w--open {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 9999;
  max-width: 100vw;
  margin-top: 105px;
  margin-left: 0px;
  padding: 0px 18px;
  border-top: 1px solid rgba(12, 15, 33, 0.07);
  border-radius: 1px;
  background-color: #f7f7f7;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-list-assortiment.w--open.notification{
  margin-top: 157px;
}

.menu-link-item-color {
  position: relative;
  display: block;
  margin-bottom: 9px;
  padding-left: 29px;
  color: #001427;
  font-size: 15px;
  text-decoration: none;
}

.menu-link-item-color:hover {
  opacity: 1;
}

.nav-link-title {
  position: relative;
  top: 2px;
  z-index: 10;
  display: inline-block;
  padding: 0px;
  border-bottom: 1px solid transparent;
  -webkit-transition: border 200ms ease;
  transition: border 200ms ease;
  font-family: Poppins, sans-serif;
  color: #001427;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}
.nav-link-title.white {
    color: #ffffff;
}
.nav-link-title:hover {
  border-bottom-color: transparent;
  text-decoration: none;
}

.nav-link-title.w--current {
  color: #11093d;
  text-decoration: underline;
}

.right-divider {
  width: 1px;
  height: 80%;
  background-color: rgba(12, 15, 33, 0.07);
}

.left-menu-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand-logo {
  margin-top: 0px;
  margin-right: 30px;
}

.dropdown-icon-dark {
  top: 3px;
  display: block;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.final-menu-item-link {
  display: block;
  margin-top: 20px;
  margin-bottom: 0px;
  padding-left: 16px;
  background-image: url('/website/images/oranje-arrow.svg');
  background-position: 0% 50%;
  background-size: 6px;
  background-repeat: no-repeat;
  font-family: Poppins, sans-serif;
  color: #ff9c1e;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
}

.final-menu-item-link._2-line {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 21px;
  margin-top: 0px;
  padding-left: 0px;
  background-image: none;
  font-size: 14px;
}

.two-grid-menu-block-right {
  position: relative;
  max-width: 330px;
  padding: 0px;
}

.dropdown.webshop {
  margin-right: 0px;
}

.webshop-dropdown-toggle {
  padding-top: 14px;
  padding-right: 20px;
  padding-bottom: 14px;
  background-color: #ff9c1e;
  color: #ffffff;
}

.navbar-new {
  padding: 0px 16px;
  background-color: #fff;
}

.nav-link-block {
  margin-right: 10px;
  padding: 14px 20px 14px 14px;
  text-decoration: none;
}

.color-wrap {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 0;
  border-color: #d1d1d1;
  border-radius: 100%;
  background-color: #0c0f21;
}

.small-menu-heading {
  margin-top: 0px;
  margin-bottom: 9px;
  font-family: Poppins, sans-serif;
  color: #2c2c2c;
  font-size: 19px;
  line-height: 28px;
  font-weight: 600;
}

.hero-img-block-img {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 82%;
    object-position: 50% 82%;
}
.hero-img-block-img._90 {
    -o-object-position: 50% 90%;
    object-position: 50% 90%;
}

.hero-img-block-img._75 {
    -o-object-position: 50% 75%;
    object-position: 50% 75%;
}
.hero-img-block-img._63 {
    -o-object-position: 50% 63%;
    object-position: 50% 63%;
}

.dropdown-form-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 35px;
  padding: 0px 0px 0px 16px;
-webkit-appearance:none;
-webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.offerte-description {
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
}

.hero-image-block-overlay {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  width: 100%;
  margin-bottom: 0px;
  padding-top: 120px;
  padding-bottom: 120px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
}

.total-form-mini-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
/*  removed set height and added flex wrap*/
/*height: 43px;*/
  flex-wrap: wrap;
  padding: 0;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /*border-style: solid;*/
  /*border-width: 1px;*/
  /*border-color: #d8d8d8;*/
  /*border-radius: 2px;*/
  font-size: 16px;
  line-height: 30px;
}

.total-form-large-block.calendar {
  position: relative;
  padding-left: 43px;
  background-image: url('/website/images/001-calendar.svg');
  background-position: 12px 50%;
  background-size: 19px;
  background-repeat: no-repeat;
}

.grid-wrapper {
  position: relative;
}

.icon {
  color: #a3a3a3;
}

.offerte-label {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.hero-section {
  position: relative;
  padding-top: 0px;
  background-color: #f1f1f1;
}

.hero-title-copy {
  margin-top: 0px;
  margin-bottom: 6px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
}

.highlight-price-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 35px;
  padding-right: 12px;
  padding-left: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.09);
  background-color: #fafafa;
}

.offerte-usp-heading-copy {
  margin-top: 0px;
  margin-bottom: 3px;
  font-family: Poppins, sans-serif;
  color: #2c2c2c;
  font-size: 22px;
  line-height: 33px;
  font-weight: 700;
  text-align: left;
}

.final-price-wrapp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

.placeholder-tekst,
.placeholder-tekst + .select2 .select2-container--default .select2-selection--single {
  display: inline-block;
  color: #0c0f21;
  font-size: 14px;
}

.placeholder-tekst._2 {
  font-size: 12px;
}

/*.placeholder-tekst.filled {*/
/*  color: #0c0f21;*/
/*}*/
input.placeholder-tekst,
.placeholder-tekst + .select2.select2-container--default .select2-selection--single{
    display: flex;
    width: 100%;
    min-height: 35px;
    padding: 0px 12px 0px 16px;
    justify-content: flex-start;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #d8d8d8;
    border-radius: 2px;
    line-height: 30px;
}
.placeholder-tekst + .select2.select2-container--default .select2-selection--single .select2-selection__rendered{
    padding: 0;
    font-size: 14px;
}
.placeholder-tekst + .select2.select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__placeholder{
    color: #757575;
}
.green-semi-bold {
  margin-left: 5px;
  padding: 3px 6px;
  border-radius: 2px;
  background-color: rgba(39, 201, 122, 0.26);
  color: #00b97c;
  font-size: 15px;
  font-weight: 600;
}

.hero-grid {
    -ms-grid-columns: 1.2fr 0.75fr;
    grid-template-columns: 1.2fr 0.75fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-row-gap: 30px;
}

.center-item-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-top: 9px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.4;
  text-align: center;
}

.highlight-green-text {
  display: inline-block;
  margin-right: 7px;
  color: #2c2c2c;
  font-size: 18px;
  font-weight: 700;
}

.offerte-button {
  width: 100%;
  height: auto;
  margin-bottom: 7px;
  padding: 13px 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: #27c97a;
  -webkit-transition: opacity 150ms ease-out, background-color 150ms ease-in;
  transition: opacity 150ms ease-out, background-color 150ms ease-in;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-align: center;
}

.offerte-button:hover {
  background-color: #26b46f;
  color: #fff;
}

.green-hero-span {
  color: #27c97a;
}

.offerte-new {
  /*position: absolute;*/
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 100%;
  max-width: 420px;
}

.offerte-new._2 {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  max-width: 420px;
    margin-bottom: -60px;
  margin-right: auto;
  margin-left: auto;
}

.service-heading {
  color: #fff;
  font-size: 23px;
  line-height: 30px;
  font-weight: 400;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.53);
}

.service-heading.smaller {
  font-size: 18px;
}

.offerte-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 35px;
  padding: 0px 12px 0px 16px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  border-radius: 2px;
  font-size: 16px;
  line-height: 30px;
}

.arrow-rotated {
  position: relative;
  top: 0px;
  width: 8px;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.incl-btw-text {
  display: inline-block;
  font-size:13px;
}

.dropdown-list-form.w--open {
  overflow: hidden;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  background-color: #e8ecf0;
  box-shadow: 0 4px 10px -5px #000;
}

.offerte-block-stap-2 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 30;
  display: block;
  width: 100%;
  max-width: 420px;
  /*margin-top: -62px;*/
  padding: 23px 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 13px 13px 50px -14px rgba(0, 0, 0, 0.14);
}

.offerte-new._2 .offerte-block-stap-2 {
  margin-top: -62px;
}

.offerte-aanpassen-mini {
  position: relative;
  top: 1px;
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
  cursor: pointer;
}

.calc-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: end;
  -ms-flex-pack: center;
  justify-content: end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-size: 12px;
}

.small-hero-heading-wrapper {
  max-width: none;
  margin-bottom: 0;
}

.dropdown-form {
  width: 100%;
  height: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
}

.small-offerte-block {
  position: relative;
  margin-bottom: 6px;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  -ms-grid-columns: 0.8fr 1fr 1fr;
  grid-template-columns: 0.8fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.text-span-2 {
  text-decoration: line-through;
  text-shadow: none;
}

.dropdown-list-form-link {
  padding: 7px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 14px;
}

.dropdown-list-form-link:hover {
  background-color: #e3e6e9;
}

.dropdown-list-form-link.final {
  border-bottom-style: none;
}

.offerte-grid-new {
  margin-bottom: 8px;
  grid-column-gap: 6px;
  grid-row-gap: 8px;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.offerte-block-stap-2 .offerte-grid-new {
  grid-column-gap: 3px;
  -ms-grid-columns: 0.4fr 1fr;
  grid-template-columns: 0.4fr 1fr;
}

.offerte-block {
  position: relative;
  z-index: 2;
  max-width: 420px;
  min-width: 420px;
  margin-top: -62px;
  padding: 23px 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 13px 13px 50px -14px rgba(0, 0, 0, 0.14);
}

.small-paragraph {
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}

.top-bar-section {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
}

.usp-list {
  display: inline-block;
  margin-right: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 22px;
  background-image: url('/website/images/001-check.svg');
  background-position: 0% 49%;
  background-size: 15px;
  background-repeat: no-repeat;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.usp-list.w {
  color: #fff;
}

.usp-list.full {
  display: block;
}

.top-menu-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 99999;
}

.large-slide-heading {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 28px;
  font-weight: 600;
}

.slider-paragraph {
  margin-bottom: 0px;
  font-family: Karla, sans-serif;
  line-height: 20px;
  font-weight: 400;
}

.fake-button {
  position: relative;
  bottom: -10px;
  display: inline-block;
  width: 100%;
  margin-bottom: -65px;
  padding: 13px 14px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: -1px 5px 12px -8px rgba(0, 0, 0, 0.24);
  color: #0c0f21;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.fake-button.green {
    background-color: #00b97c;
    transition: opacity 150ms ease-out, background-color 150ms ease-in;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 10px 18px 9px;
    border-radius: 2px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
}
.fake-button:hover {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.large-image-item {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 410px;
  border-radius: 2px;
  background-image: linear-gradient(220deg, rgba(152, 28, 31, 0) 37%, #981c1f);
  box-shadow: 0 10px 50px -9px rgba(0, 0, 0, 0.14);
}

.intro-section {
  position: relative;
  padding: 90px 18px 100px;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.9%, 0.97)), to(hsla(0, 0%, 96.9%, 0.97))), url('/website/images/Woodstpe-zwart.svg');
  background-image: linear-gradient(180deg, hsla(0, 0%, 96.9%, 0.97), hsla(0, 0%, 96.9%, 0.97)), url('/website/images/Woodstpe-zwart.svg');
  background-position: 0px 0px, 150% 50%;
  background-size: auto, 1060px;
  background-repeat: repeat, no-repeat;
}

.basic-quad {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.basic-triple {
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.basic-showroom {
    max-width: 1000px;
    gap: 40px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    padding-bottom:20px;
}
.basic-showroom .large-image-item{
    height: 500px;
}
.basic-showroom .large-image-item-overlay{
    background: linear-gradient(180deg, transparent 60%, rgba(0, 20, 39, 1) 80%, rgba(0, 20, 39, 1));
}
.nav-block-showroom{
    margin-top: 55px;
}
.large-slide-bot-block {
  position: absolute;
  left: 0;
  top: auto;
  right: 0;
  bottom: 0%;
  padding: 0px 24px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.basic-section-heading {
  margin-top: 0px;
  font-family: Poppins, sans-serif;
  color: #000;
  font-size: 33px;
  line-height: 40px;
  font-weight: 700;
}

.center-heading-block {
  max-width: 575px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  text-align: center;
}

.large-image-category {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  -o-object-fit: cover;
  object-fit: cover;
}

.large-image-item-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-image: -webkit-gradient(linear, 100% 0, 0 0, color-stop(0.1, transparent), color-stop(0.5, rgba(0, 14, 27, 0.27)), to(rgba(0, 20, 39, 0.76)));
  background-image: linear-gradient(180deg, transparent 10%, rgba(0, 14, 27, 0.27) 50%, rgba(0, 20, 39, 0.76));
}

.usp-bar {
  padding: 20px 18px;
  background-color: #001427;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 20, 39, 0.96)), to(rgba(0, 20, 39, 0.96))), url('/website/images/Woodstep-Wit.svg');
  background-image: linear-gradient(180deg, rgba(0, 20, 39, 0.96), rgba(0, 20, 39, 0.96)), url('/website/images/Woodstep-Wit.svg');
  background-position: 0px 0px, 0% 50%;
  background-size: auto, 1000px;
  background-repeat: repeat, no-repeat;
}

._2-col-grid {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.cta-section-text {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 600;
}

.cta-description {
  color: #fff;
  font-size: 13px;
}

.bold-text-4 {
  color: #fff;
}

.button-full {
  width: 100%;
  margin-top: 0px;
  margin-right: 10px;
  padding: 10px 18px 9px;
  border-radius: 2px;
  /*background-color: #ff9c1e;*/
  background-color: #00b97c;
  -webkit-transition: opacity 150ms ease-out, background-color 150ms ease-in;
  transition: opacity 150ms ease-out, background-color 150ms ease-in;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.button-full:hover {
  background-color: #b3906d;
  color: #fff;
}

.button-full._2 {
  width: auto;
  margin-right: 0px;
  /*background-color: #ff9c1e;*/
  background-color: #00b97c;
}

.mini-slider {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  margin-top: 12px;
  margin-left: 12px;
  padding-top: 0px;
  font-size: 5px;
}

.section-image-copy {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 490px;
  text-align: center;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 100%;
  object-position: 50% 100%;
}

.section-image-copy._1 {
  -o-object-position: 50% 80%;
  object-position: 50% 80%;
}

.section-image-copy._2 {
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.large-image-text {
  position: relative;
  z-index: 100;
  margin-top: -105px;
  margin-bottom: 0px;
  padding: 30px 35px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: -3px 18px 50px -14px rgba(0, 0, 0, 0.14);
}

.large-image-text.klanten {
  margin-top: 55px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.button-gtid {
  max-width: 450px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button-gtid.top-margin {
  margin-top: 20px;
}

.google-image {
    width: 45px;
    text-align: center;
    height: 45px;
    margin-right: 16px;
    border-radius: 100%;
    line-height: 45px;
    font-size: 20px;
    font-weight: bold;
    color: #ffff;
}
.google-image._1{
    background-color: #00897b;
}
.google-image._2{
    background-color: #455a64;
}
.google-image._3{
    background-color: #ab47bc;
}

.arrow-left {
  display: none;
  width: 35px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.63);
}

.top-right-woodstep-slider-block {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 8;
  display: none;
  margin-right: 34px;
  padding: 11px;
  background-color: rgba(0, 0, 0, 0.63);
  color: #fff;
  font-weight: 500;
}

.google-name {
  display: inline-block;
  color: #111111;
}

._2-grid-block {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.showroom-text-block {
  position: relative;
  max-width: 430px;
  margin-bottom: 0px;
}

.section-tint {
  position: relative;
  padding: 75px 18px;
  background-color: #f1f1f1;
}

.section-tint.bot-padding {
  padding-bottom: 150px;
}

.google-stars {
  display: inline-block;
  height: 20px;
}

.arrow-icon {
  font-size: 16px;
}

.div-block-2 {
  margin-bottom: 13px;
}

.arrow-right {
  display: none;
  width: 35px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.63);
}

.icon-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 46px;
  margin-left: 0px;
  padding: 6px 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #001427;
  text-decoration: none;
}

.icon-button:hover {
  background-color: #0c0f21;
}

.triple-grid-review {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-row-gap: 16px;
}

.mask-2 {
  border-radius: 3px;
}

.large-woodstep-slider {
  height: 490px;
}

.trustpilot-wrap {
  padding: 17px 24px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  background-color: #fafafa;
  box-shadow: none;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.trustpilot-wrap:hover {
  box-shadow: none;
}

.large-paragraph {
  color: #000;
  font-size: 17px;
  line-height: 27px;
}

.large-paragraph.white {
  color: #fff;
}

.large-paragraph.blog {
  margin-bottom: 13px;
}

.trustpilot-heading {
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.usp-heading-wrapper-center {
  max-width: 590px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  color: #0c0f21;
  text-align: center;
}

.usp-heading-wrapper-center._2 {
  margin-top: 50px;
}

.large-woodstep-slide {
  z-index: 1;
}

.div-block-3 {
  position: relative;
  top: -13px;
  margin-bottom: -13px;
}

.green-span {
  float: right;
  color: #00b67a;
  font-size: 22px;
  font-weight: 500;
  text-align: right;
}

.green-span-small {
  display: inline-block;
  margin-right: 0px;
  float: right;
  color: #0c0f21;
  font-size: 11px;
  font-weight: 500;
}

.usp-heading-wrapper-copy {
  max-width: 590px;
  margin-bottom: 0px;
}

.category-grid-wrapper {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.category-text {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
}

.project-mini-heading {
  margin-bottom: 4px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-align: left;
}

.right-top-item {
  position: relative;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: inline-block;
  margin-bottom: 8px;
  padding: 4px 12px 3px;
  border-radius: 2px;
  background-color: #2c2c2c;
  box-shadow: 0 4px 11px -10px rgba(0, 0, 0, 0.28);
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}

.combi-deal-grid {
  height: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 25px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.fake-button-wrapper {
  display: inline-block;
}

.fake-button-wrapper:hover {
  color: #981c1f;
}

.bespaar-item {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 0px;
  margin-right: 7px;
  padding: 7px;
  background-color: #00b67a;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 600;
}

.fake-button-text {
  display: inline-block;
  font-size: 15px;
  color: #001427;
}

.before-block-top {
  position: relative;
  margin-top: 0px;
  padding: 0px 12px 51px;
  border-radius: 2px;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.98)), to(hsla(0, 0%, 100%, 0.98)));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.98), hsla(0, 0%, 100%, 0.98));
  background-position: 0px 0px;
  background-size: auto;
  box-shadow: 0 5px 11px -9px #000;
  text-align: center;
}

.assortiment-grid {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  grid-column-gap: 10px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.large-link-image {
  width: 75px;
  margin-right: 8px;
}

@media screen and (max-width: 525px) {
    .large-link-image {
        width: 55px;
    }
    .category-text{
        font-size: 13px;
    }
}
.before-block-image-wrap {
  position: relative;
}


.fake-button-line {
  width: 100%;
  height: 2px;
  background-color: #981c1f;
}

.mini-usp-heading-paragraph {
  color: #000;
  font-size: 17px;
}

.large-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 66%, 0.28);
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 5px 16px -12px rgba(0, 0, 0, 0.6);
  color: #001427;
  text-decoration: none;
}

.large-link:hover {
  box-shadow: 0 5px 16px -9px rgba(0, 0, 0, 0.6);
}

.text-span-3 {
  text-decoration: line-through;
}

.div-block-5 {
  margin-top: 5px;
  padding-left: 8px;
  text-align: right;
}

.deal-grid {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  -ms-grid-columns: 0.25fr 0.75fr;
  grid-template-columns: 0.25fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.before-text {
  margin-left: 7px;
  color: #0c0f21;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
}

.combideal-button-wrapper {
  margin-top: 12px;
}

._2-col-aanbieding {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 0.75fr;
  grid-template-columns: 1fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.basic-section-tint {
  position: relative;
  padding: 50px 18px;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.9%, 0.97)), to(hsla(0, 0%, 96.9%, 0.97))), url('/website/images/Woodstpe-zwart.svg');
  background-image: linear-gradient(180deg, hsla(0, 0%, 96.9%, 0.97), hsla(0, 0%, 96.9%, 0.97)), url('/website/images/Woodstpe-zwart.svg');
  background-position: 0px 0px, 150% 50%;
  background-size: auto, 1060px;
  background-repeat: repeat, no-repeat;
}

.image-section {
  position: relative;
  height: auto;
  padding: 0px;
  background-color: #fff;
}

.section-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  text-align: center;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 100%;
  object-position: 50% 100%;
}

.image-section-overlay {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  padding: 48px 18px 130px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(152, 28, 31, 0.88)), to(rgba(152, 28, 31, 0.88)));
  background-image: linear-gradient(180deg, rgba(152, 28, 31, 0.88), rgba(152, 28, 31, 0.88));
}

.bot {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.05);
}

.final-footer-link {
  display: inline-block;
  font-family: Poppins, sans-serif;
  color: hsla(0, 0%, 100%, 0.27);
  font-size: 11px;
  text-decoration: none;
}

.final-footer-link._2 {
  margin-left: 8px;
}

.footer-block-left {
  padding-right: 0px;
}

.footer-block-left._1 {
  position: relative;
  left: 45px;
}

.footer-link-title {
  display: inline-block;
  color: #fff;
  font-weight: 400;
}

.usp-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.usp-grid._2 {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.openingstijden-block {
  margin-bottom: 4px;
  justify-items: start;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 0.75fr;
  grid-template-columns: 1fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-link-wrapper-copy {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 0px;
  padding: 10px 10px;
  border-radius: 3px;
  background-color: hsla(0, 0%, 100%, 0.04);
  text-decoration: none;
}

.footer-link-wrapper-copy:hover {
  background-color: hsla(0, 0%, 100%, 0.08);
}

.usp-link-block {
  margin-right: 8px;
  margin-left: 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #000;
  text-decoration: none;
}

.small-usp-logo {
  position: relative;
  top: -2px;
  width: 16px;
  margin-right: 8px;
  -webkit-filter: brightness(0%) invert(100%) grayscale(100%);
  filter: brightness(0%) invert(100%) grayscale(100%);
}

.small-usp-logo._2 {
  -webkit-filter: none;
  filter: none;
}

.small-usp-logo._2.sm {
  width: 14px;
  opacity: 0.38;
}

.footer-link {
  display: block;
  margin-top: 13px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 13px;
  text-decoration: none;
}

.footer-link:hover {
  color: #b80102;
}

.coming-soon-tag {
  position: relative;
  top: -1px;
  display: inline-block;
  margin-left: 10px;
  padding-top: 1px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 2px;
  background-color: hsla(0, 0%, 100%, 0.06);
  color: hsla(0, 0%, 100%, 0.8);
  font-size: 8px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.footer {
  position: relative;
  padding: 50px 18px 125px;
  background-color: #001427;
  /*background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 20, 39, 0.98)), to(rgba(0, 20, 39, 0.98))), url('/website/images/Woodstep-Wit.svg');*/
  /*background-image: linear-gradient(180deg, rgba(0, 20, 39, 0.98), rgba(0, 20, 39, 0.98)), url('/website/images/Woodstep-Wit.svg');*/
  background-position: 0px 0px, 50% 50%;
  background-size: auto, 1300px;
  background-repeat: repeat, no-repeat;
}

.final-footer-divider {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  padding-top: 18px;
  padding-bottom: 18px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.05);
}

.final-footer-divider.odd {
  position: relative;
}

.footer-title {
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}

.usp-link-text {
  display: inline;
  color: #fff;
  text-decoration: none;
}

.usp-grid-large {
  margin-bottom: 25px;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-block {
  margin-right: 0px;
  margin-bottom: 20px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 13px;
}

.footer-block.alt {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.trustpilot-link {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #0c0f21;
}

.trustpilot-link:hover {
  color: #00b67a;
}

.basic-2-grid {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.usp-wrapper {
  padding-top: 23px;
  padding-bottom: 19px;
}

.footer-grid-new {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-link-block-copy {
  display: block;
  margin-bottom: 0px;
  padding-left: 0px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
}

.hero-sub-heading {
  margin-bottom: 7px;
  font-size: 21px;
  line-height: 30px;
}

.bedankt-blok-right-image {
  box-shadow: 1px 1px 50px -4px rgba(0, 0, 0, 0.1);
}

.dark-title {
  margin-top: 0px;
  margin-bottom: 3px;
  font-family: Poppins, sans-serif;
  color: #0c0f21;
  font-size: 39px;
  line-height: 54px;
  font-weight: 700;
}

.bedankt-hero-wwrapper {
  max-width: 510px;
  margin-bottom: 16px;
}

.bedankt-grid {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.bedankt-img {
  border-radius: 2px;
}

.bedankt-section {
  position: relative;
  padding: 80px 18px;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.9%, 0.97)), to(hsla(0, 0%, 96.9%, 0.97))), url('/website/images/Woodstpe-zwart.svg');
  background-image: linear-gradient(180deg, hsla(0, 0%, 96.9%, 0.97), hsla(0, 0%, 96.9%, 0.97)), url('/website/images/Woodstpe-zwart.svg');
  background-position: 0px 0px, 150% 50%;
  background-size: auto, 1060px;
  background-repeat: repeat, no-repeat;
}

.small-hero-button-wrapper {
  margin-top: 24px;
}

.transparant-button {
  margin-top: 0px;
  margin-right: 10px;
  padding: 10px 18px 9px;
  border-radius: 2px;
  background-color: hsla(0, 0%, 100%, 0.03);
  -webkit-transition: opacity 150ms ease-out, background-color 150ms ease-in;
  transition: opacity 150ms ease-out, background-color 150ms ease-in;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.transparant-button:hover {
  background-color: rgba(226, 226, 226, 0.09);
  color: #fff;
}

.category-hero-new {
  position: relative;
}

.category-hero-new.blog {
  height: 330px;
}
.category-hero-new.voorwaarden {
  height: 250px;
}

.breadcrumb-link {
  margin-right: 6px;
  color: #fff;
  text-decoration: none;
}

.breadcrumb-link.w--current {
  text-decoration: underline;
}

.breadcrumb-wrapper {
  position: relative;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 10;
  margin-right: 6px;
  margin-bottom: 10px;
  text-align: left;
}

.breadcrumb-arrow {
    height: 11px;
    width: 15px;
    margin-top: 4px;
    margin-right: 6px;
}

.new-heading-wrapper {
  max-width: 600px;
  margin-top: 0px;
  text-align: left;
}
.new-heading-wrapper.w-100 {
    max-width: 100%;
}

.subpage-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 42px;
  line-height: 58px;
  font-weight: 700;
}

.basic-button {
  margin-top: 0px;
  margin-right: 10px;
  padding: 10px 18px 9px;
  border-radius: 2px;
  background-color: #00b97c;
  -webkit-transition: opacity 150ms ease-out, background-color 150ms ease-in;
  transition: opacity 150ms ease-out, background-color 150ms ease-in;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.breadcrumb-text {
  position: relative;
  top: 3px;
  font-weight: 500;
}

.category-image-overlay-new {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  padding: 45px 10px;
  background: rgba(152, 25, 21, 0.88);
  /*background-color: rgba(14, 19, 27, 0);*/
  /*background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.21)), color-stop(55%, rgba(19, 19, 19, 0.07)), to(transparent));*/
  /*background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.21), rgba(19, 19, 19, 0.07) 55%, transparent);*/
}

.category-image-overlay-new._2 {
  padding-top: 56px;
}

.breadcrumb-home-icon {
  position: relative;
  top: 1px;
  width: 14px;
}

.category-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.icon-wrapper-mini {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  margin-bottom: 0px;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(152, 28, 31, 0.07);
  text-align: center;
}

.icon-wrapper-mini._2 {
  width: 45px;
  height: 45px;
  margin-bottom: 14px;
  padding: 8px;
}

.icon-wrapper-mini._2.center {
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
}

.icon-wrapper-mini.nadeel {
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  background-color: transparent;
}

.usp-icon {
  width: 35px;
  height: 35px;
  -o-object-fit: fill;
  object-fit: fill;
}

.usp-icon.nadeel {
  opacity: 1;
}

.large-content-section {
  position: relative;
  padding: 100px 18px;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.9%, 0.97)), to(hsla(0, 0%, 96.9%, 0.97))), url('/website/images/Woodstpe-zwart.svg');
  background-image: linear-gradient(180deg, hsla(0, 0%, 96.9%, 0.97), hsla(0, 0%, 96.9%, 0.97)), url('/website/images/Woodstpe-zwart.svg');
  background-position: 0px 0px, 150% 50%;
  background-size: auto, 1060px;
  background-repeat: repeat, no-repeat;
}

.large-content-section.offerte {
  padding-top: 70px;
}

.large-content-section.top-padding {
  padding-top: 0px;
}
.large-content-section.white {
  background-color: #fff;
    background-image:none;
}

.button-no-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 46px;
  margin-left: 0px;
  padding: 6px 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #001427;
  text-decoration: none;
}

.button-no-icon:hover {
  background-color: #0c0f21;
}

.content-grid {
  margin-bottom: 69px;
  -ms-grid-columns: 1.25fr 1fr;
  grid-template-columns: 1.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.content-grid.reverse {
  -ms-grid-columns: 1fr 1.25fr;
  grid-template-columns: 1fr 1.25fr;
}

.content-grid.reverse._2 {
  margin-bottom: 0px;
}

.content-grid.reverse.top {
  margin-top: 75px;
  margin-bottom: 0px;
}

.content-grid.final {
  margin-bottom: 0px;
}
.partners-grid{
    -ms-grid-columns: 3.25fr 1fr;
    grid-template-columns: 3.25fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.large-image-wrapper-cat {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 410px;
  border-radius: 2px;
  /*background-image: linear-gradient(220deg, rgba(152, 28, 31, 0) 37%, #981c1f);*/
  box-shadow: 0 10px 50px -9px rgba(0, 0, 0, 0.14);
}

.large-image-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  /*background-image: linear-gradient(225deg, transparent 29%, #981c1f);*/
}

.usp-heading-wrapper {
  max-width: 595px;
  margin-bottom: 50px;
}

.usp-heading-wrapper.center {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.usp-heading-wrapper.center._0 {
  margin-bottom: 20px;
}

.usp-heading {
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 8px;
  font-family: Poppins, sans-serif;
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 700;
}

.paragraph-usp {
  color: #2c2c2c;
}

.usp-block {
  position: relative;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}

.grid-triple-usp-large {
  grid-column-gap: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-blok-grid {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.large-video-wrapper {
  padding: 30px 35px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 13px 13px 15px -14px rgba(0, 0, 0, 0.14);
}

.large-table-top-item {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #151623;
}

.large-table-top-item.extra {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.large-table-top-item.extra._5,
.table-item-item.extra._5 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.table-top-heading-2 {
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
}

.large-table-wrapper {
  position: relative;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 2px;
}

.table-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 11px;
  padding-bottom: 11px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  text-align: center;
}

.table-block._2 {
  border-bottom-style: none;
}

.table-block.final {
  border-right-style: none;
}

.table-block.first.final {
  border-bottom-style: none;
}

.small-check {
  width: 16px;
  margin-right: 12px;
}

.table-item-item {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #f1f1f1;
}

.table-item-item.extra {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-top-block {
  border-right: 1px solid hsla(0, 0%, 100%, 0.21);
  text-align: center;
}

.table-left-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: #2c2c2c;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
}

.slider-section-slider {
  height: 500px;
}

.section-slider-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.slider-arrow {
  width: 50px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.27);
  font-size: 17px;
}

.slider-hide {
  display: none;
}

.step-block {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: inline-block;
  padding: 6px 11px;
  border-radius: 2px;
  background-color: hsla(0, 0%, 64%, 0.24);
  font-size: 13px;
  font-weight: 500;
}

.step-block._2 {
  margin-top: 6px;
}

.werkwijze-block {
  position: relative;
  max-width: 430px;
  margin-bottom: 0px;
}

.menu-item-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.right-menu-description {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
}

.right-menu-link-block-showroom {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 11px 25px 11px 85px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: none;
  border-width: 1px;
  border-color: hsla(0, 0%, 60.7%, 0.28);
  border-radius: 2px;
  background-color: #fff;
  color: #1f1f1f;
  text-decoration: none;
}

.right-menu-link-block-showroom:hover {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.klantenservice-top-grid {
  margin-bottom: 20px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 0.45fr;
  grid-template-columns: 1fr 0.45fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.werkgebied-top-grid {
    margin-bottom: 20px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 0.6fr 0.5fr;
    grid-template-columns: 0.6fr 0.5fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.werkgebied-top-grid .hero-image-block-wrapper.large{
    height: auto;
}


.contact-map {
  width: 100%;
  height: 100%;
}

.block-heading {
  margin-top: 0px;
  margin-bottom: 14px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  line-height: 32px;
}

.block-heading.vacature {
  margin-bottom: 0px;
}

.right-col-grid-showroom {
  height: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
  box-shadow: -2px 10px 20px -20px rgba(0, 0, 0, 0.29);
}

.hero-image-block-wrapper {
  position: relative;
  overflow: visible;
  height: 470px;
  border-radius: 3px;
}

.hero-image-block-wrapper.large {
  height: 490px;
}

.klantenservice-section {
  position: relative;
  padding: 40px 18px;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.9%, 0.97)), to(hsla(0, 0%, 96.9%, 0.97))), url('/website/images/Woodstpe-zwart.svg');
  background-image: linear-gradient(180deg, hsla(0, 0%, 96.9%, 0.97), hsla(0, 0%, 96.9%, 0.97)), url('/website/images/Woodstpe-zwart.svg');
  background-position: 0px 0px, 150% 50%;
  background-size: auto, 1060px;
  background-repeat: repeat, no-repeat;
}

.icon-wrapper-klantenservice {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 45px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 20px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(152, 28, 31, 0.07);
  text-align: center;
}

.klantenservice-triple-grid {
  grid-column-gap: 20px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
@media screen and (min-width: 768px){
    .klantenservice-triple-grid{
        -ms-grid-columns: 1.5fr 1fr 1fr;
        grid-template-columns: 1.5fr 1fr 1fr;
    }
}
@media screen and (min-width: 991.1px){
    .klantenservice-triple-grid{
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .werkgebied-top-grid aside{
        height: 100%;
        max-height: 490px;
    }
}

.basic-white-block {
  padding: 24px 30px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 7px 7px 20px -20px rgba(0, 0, 0, 0.29);
}

.veelgestelde-vragen-main-grid {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.faq-main-item-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 17px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 7px 7px 20px -20px rgba(0, 0, 0, 0.29);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.faq-main-item-wrapper:hover {
  box-shadow: 7px 7px 20px -16px rgba(0, 0, 0, 0.87);
}

.faq-item-icon {
  display: block;
  width: 50px;
  height: 50px;
  margin-bottom: 14px;
}

.faq-main-item-text {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-align: center;
  color: #001427;
}

.triple-grid-veelgestelde-vragen {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.veelgestelde-vragen-heading {
  margin-top: 0px;
  margin-bottom: 14px;
  margin-left: 60px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  line-height: 32px;
}

.icon-wrapper-klantenservice-small {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 45px;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 20px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(152, 28, 31, 0.07);
  text-align: center;
}

.klantenservice-block {
  position: relative;
  padding: 24px 20px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 7px 7px 20px -20px rgba(0, 0, 0, 0.29);
}

.klantenservice-block.antwoord {
  padding-right: 32px;
  padding-left: 32px;
}

.klantenservice-link {
  display: block;
  margin-bottom: 5px;
  color: #001427;
  font-size: 15px;
  text-decoration: none;
}

.search-wrapper {
  max-width: 700px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
}

.search-bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 9px 11px 9px 23px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 7px 7px 20px -20px rgba(0, 0, 0, 0.29);
}

.search-buttons {
  display: inline-block;
  width: auto;
  margin-top: 0px;
  margin-right: 0px;
  padding: 10px 18px 9px;
  float: right;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  background-color: #ff9c1e;
  -webkit-transition: opacity 150ms ease-out, background-color 150ms ease-in;
  transition: opacity 150ms ease-out, background-color 150ms ease-in;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.search-buttons:hover {
  background-color: #b3906d;
  color: #fff;
}

.search-text {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: rgba(0, 20, 39, 0.51);
  font-size: 15px;
}

.grid-vragen {
  margin-top: -28px;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dropdown-klantenservice {
  display: block;
}

.dropdown-toggle-klantenservice {
  width: 100%;
  padding-left: 58px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.dropdown-toggle-klantenservice.final {
  border-bottom-style: none;
}

.icon-wrapper-klantenservice-mini {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 45px;
  margin-top: 7px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(152, 28, 31, 0.07);
  text-align: center;
}

.klantenservice-dropdown-list.w--open {
  position: relative;
  background-color: transparent;
}

.klantenservice-dropdown-link {
  padding-left: 30px;
  background-color: #fff;
  background-image: url('/website/images/right-arrow.svg');
  background-position: 10px 50%;
  background-size: 8px;
  background-repeat: no-repeat;
}

.klantenservice-dropdown-link:hover {
  border-radius: 2px;
  background-color: #f7f7f7;
}

.left-mini-klantenservice {
  position: relative;
  margin-top: 0px;
  padding: 18px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 7px 7px 20px -20px rgba(0, 0, 0, 0.29);
}

.klantenservice-antwoord-section {
  position: relative;
  padding: 0px 18px 100px;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.9%, 0.97)), to(hsla(0, 0%, 96.9%, 0.97))), url('/website/images/Woodstpe-zwart.svg');
  background-image: linear-gradient(180deg, hsla(0, 0%, 96.9%, 0.97), hsla(0, 0%, 96.9%, 0.97)), url('/website/images/Woodstpe-zwart.svg');
  background-position: 0px 0px, 150% 50%;
  background-size: auto, 1060px;
  background-repeat: repeat, no-repeat;
}

.terug-small {
  display: inline-block;
  color: #001427;
}

.antwoord-block {
  margin-top: 17px;
}

.dropdown-av {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.dropdown-av.final {
  border-bottom-style: none;
}

.dropdown-av-toggle {
  width: 100%;
  padding: 10px 0px;
}

.dropdown-heading {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.dropdown-list-av.w--open {
  position: relative;
  background-color: transparent;
}

.dropdown-icon {
  margin-right: 0px;
}

.paragraph-grid {
  grid-column-gap: 35px;
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block {
  padding-right: 55px;
}

.intro-description {
  max-width: 590px;
  margin-bottom: 0px;
}

.section-heading-left {
  margin-top: 0px;
  font-family: Poppins, sans-serif;
  color: #000;
  font-size: 33px;
  line-height: 40px;
  font-weight: 700;
}

.highlight-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: #001427;
  font-size: 17px;
  line-height: 34px;
  font-weight: 600;
}

.combi-deal-4 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.combideal-pack {
  margin-top: 62px;
}

.main-blog-grid {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.blog-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 28px;
  font-weight: 600;
}

.blog-overlay-bot {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 100%;
  padding: 22px 22px 0px;
}

.geschreven-op {
  display: inline-block;
}

.calendar-icon {
  position: relative;
  top: -2px;
  width: 17px;
  margin-right: 5px;
}

.time-right-top {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 4px;
  color: #fff;
}

.final-blog-grid {
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.final-blog-block {
  margin-bottom: 16px;
  padding: 10px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 3px 11px -6px rgba(0, 0, 0, 0.32);
}

.final-blog-block-grid {
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.final-blog-block-image {
  width: 100%;
  min-height: 190px;
  border-radius: 3px;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
}

.final-blog-heading {
  margin-top: 0px;
  margin-bottom: 3px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.relative-block-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: linear-gradient(225deg, transparent, rgba(0, 20, 39, 0.7));
}

.time-right-top-mini {
  margin-top: 8px;
  margin-right: 8px;
  margin-bottom: 5px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  color: #fff;
}

.outline-button {
  width: auto;
  margin-top: 0px;
  margin-right: 10px;
  padding: 10px 18px 9px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 20, 39, 0.19);
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: opacity 150ms ease-out, background-color 150ms ease-in;
  transition: opacity 150ms ease-out, background-color 150ms ease-in;
  font-family: Poppins, sans-serif;
  color: #001427;
  font-weight: 600;
}
.line-button {
    /*position: absolute;*/
    /*left: 0%;*/
    /*top: auto;*/
    /*right: 0%;*/
    /*bottom: 0%;*/
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 46px;
    margin-left: 0px;
    padding: 6px 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 20, 39, 0.09);
    border-radius: 3px;
    background-color: transparent;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-family: Poppins, sans-serif;
    color: #001427;
    font-size: 13px;
    text-decoration: none;
}

.outline-button:hover {
  background-color: #b3906d;
  color: #fff;
}

.line-button:hover {
    border-color: #001427;
}

.center-button-wrapper {
  text-align: center;
}

.final-blog-item {
  padding-left: 17px;
}

.social-blog-wrapper {
  padding-top: 21px;
  padding-left: 21px;
}

.social-blog-item {
  display: block;
  width: auto;
  margin-right: 8px;
  margin-bottom: 10px;
  margin-left: 0px;
  padding: 10px 12px;
  border-radius: 3px;
  background-color: #001427;
  text-decoration: none;
}

.social-blog-item:hover {
  background-color: #001427;
}

.nieuwsbrief-wrap {
  overflow: hidden;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;
}

.nieuwsbrief-form {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 45px;
  margin-top: 2px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #fff;
}

.arrow-button {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  padding: 11px;
  background-color: #ff9c1e;
}

.email-placeholder {
  color: rgba(0, 0, 0, 0.34);
  font-size: 15px;
    height: 100%;
    width: 100%;
    padding-left: 17px;
    border: none;
}

.large-content-section-blog {
  position: relative;
  padding: 0px 18px 100px;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.9%, 0.97)), to(hsla(0, 0%, 96.9%, 0.97))), url('/website/images/Woodstpe-zwart.svg');
  background-image: linear-gradient(180deg, hsla(0, 0%, 96.9%, 0.97), hsla(0, 0%, 96.9%, 0.97)), url('/website/images/Woodstpe-zwart.svg');
  background-position: 0px 0px, 150% 50%;
  background-size: auto, 1060px;
  background-repeat: repeat, no-repeat;
}

.blog-post-wrapper {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin: -80px auto 0px;
  padding: 41px 70px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 1px 1px 40px 0 rgba(0, 0, 0, 0.1);
}

.rich-text-block {
  color: #151623;
  font-size: 16px;
  line-height: 27px;
}

.full-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.block-wrapper {
  max-width: 500px;
}

.white-heading {
  margin-top: 0px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 20px;
}

.temp-button {
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 1px;
  background-color: hsla(0, 0%, 100%, 0.09);
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 400;
}

.houtsoort-grid {
  height: auto;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.select-heading-wrap {
  max-width: 610px;
  margin-left: 0px;
}

.select-heading-wrap-md {
  max-width: 700px;
  margin-left: 0px;
}

.choose-icon {
  width: 30px;
}

.choose-icon.small {
  position: relative;
  left: 0px;
  top: 0px;
  width: 14px;
}

.houtlook-img-wrp {
  position: relative;
}

.small-kleur-heading {
  margin-top: 0px;
  font-size: 22px;
}

.small-kleur-heading._2 {
  margin-bottom: 0px;
}

.right-item-wrap {
  position: relative;
}

.grey-button {
  width: 220px;
  margin-top: 0px;
  padding: 10px 18px 9px;
  border-radius: 2px;
  background-color: hsla(0, 0%, 64%, 0.63);
  -webkit-transition: opacity 150ms ease-out, background-color 150ms ease-in;
  transition: opacity 150ms ease-out, background-color 150ms ease-in;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.grey-button:hover {
  background-color: #a3a3a3;
  color: #fff;
}

.grey-button.full {
  width: 100%;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  text-align: center;
}

.step-heading {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 6px;
  margin-left: 0px;
  font-family: Poppins, sans-serif;
  color: #2c2c2c;
  font-size: 22px;
  font-weight: 700;
}
h1.step-heading {
  font-size: 32px;
}

.houtsoort-image {
  width: 100%;
  height: 160px;
  border-radius: 2px;
  -o-object-fit: cover;
  object-fit: cover;
}

.center-mini-heading {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
}

.center-mini-heading.chosen {
  color: #27c97a;
}

.step-heading-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.houtlook-img-overl {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 26px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.34);
/*background-color: hsla(0, 55%, 49%, 0.34);*/
  font-family: Lato, sans-serif;
  color: #0c0f21;
  font-size: 70px;
  font-weight: 900;
}

.large-item {
  width: 100%;
  height: 220px;
  margin-bottom: 11px;
  border-radius: 2px;
  -o-object-fit: cover;
  object-fit: cover;
}

.plint-link,
.houtsoort-link {
  padding-bottom: 0px;
  text-align: center;
}

.plint-link.chosen,
.houtsoort-link.chosen {
  padding-bottom: 0px;
}

.double-grid-block {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.75fr 0.5fr;
  grid-template-columns: 0.75fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.right-block-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: hsla(0, 0%, 64%, 0.09);
}

.right-block-wrap._2 {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.large-offerte-block {
  margin-bottom: 50px;
  padding: 30px 35px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 13px 13px 15px -14px rgba(0, 0, 0, 0.14);
}

.chosen-item {
  position: relative;
  overflow: hidden;
  border: 1px solid #27c97a;
  border-radius: 3px;
}

.chosen-item-block {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(39, 201, 122, 0.24);
}

.orange-button {
  width: 220px;
  margin-top: 0px;
  padding: 10px 18px 9px;
  border-radius: 2px;
  background-color: hsla(0, 0%, 64%, 0.63);
  -webkit-transition: opacity 150ms ease-out, background-color 150ms ease-in;
  transition: opacity 150ms ease-out, background-color 150ms ease-in;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.orange-button:hover {
  background-color: #a3a3a3;
  color: #fff;
}

.orange-button.full {
  width: 100%;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  background-color: #ff9c1e;
  text-align: center;
}
.orange-button.kto {
  background-color: #ff9c1e;
  text-align: center;
}

.color-grid {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.small-color-item {
  position: relative;
  width: 100%;
  height: 67px;
  border-radius: 2px;
  -o-object-fit: cover;
  object-fit: cover;
}

.houtsoort-grid-copy {
  height: auto;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.form-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 43px;
  margin-bottom: 0px;
  padding: 0px 12px 0px 16px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  border-radius: 2px;
  font-size: 14px;
  line-height: 30px;
}

.small-plint-image {
  border-radius: 2px;
}

.double-grid {
  margin-bottom: 14px;
  -ms-grid-columns: 0.33fr 1fr;
  grid-template-columns: 0.33fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.form-block-2 {
  margin-bottom: 0px;
}

.form-block-2._2 {
  margin-top: 7px;
}

.houtsoort-image-2 {
  width: 100%;
  height: 185px;
  border-radius: 2px;
  -o-object-fit: cover;
  object-fit: cover;
}

.form-2 {
  position: relative;
}

.plint-block {
  height: 100%;
}

.mini-heading {
  position: relative;
  margin-bottom: 7px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.plint-image {
  width: 100%;
  height: 185px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  -o-object-fit: cover;
  object-fit: cover;
}

.content-wrapper {
  height: 100%;
}

.right-question-link {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: block;
  margin-top: 0px;
  margin-right: 0px;
}

.ja-nee-grid {
  width: 100%;
  height: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.right-question-link-image {
  width: 14px;
  height: 14px;
}

.edit-icon-wrapper {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 18px;
  height: 18px;
  margin-top: 9px;
  opacity: 0.15;
}

.edit-icon-wrapper:hover {
  opacity: 1;
}

.grid-block-small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.16);
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 600;
}

.grid-block-small.highlight {
  border-right-style: none;
  background-color: #27c97a;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: repeat;
  color: #fff;
}

.grid-block-small._2 {
  border-right-style: none;
}

.question-answer {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 2;
  width: 330px;
  margin-top: -75px;
  margin-right: 0px;
  margin-left: -209px;
  padding: 5px 9px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 5px 8px -9px #000;
  color: rgba(3, 20, 39, 0.91);
}

.specifactie-block {
  position: relative;
}

._6-grid {
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.ja-nee-wrapper {
  width: 100%;
  height: 43px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 84.7%, 0.34);
  border-radius: 3px;
  background-color: #f0f0f0;
}

.choose-block-simple {
  position: relative;
  margin-bottom: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.choose-heading {
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 19px;
}

.choose-heading._2 {
  font-family: Karla, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.question-answer-text {
  color: #0c0f21;
  font-size: 13px;
  font-weight: 400;
}

.bold-mini-heading {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 600;
}

.upload-field {
  display: -moz-inline-block;
  display: inline-block;
  width: 100%;
  min-height: 300px;
  padding: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px dashed #9bbae8;
  border-radius: 3px;
  background-color: #fcfcff;
  color: #001427;
  font-size: 11px;
  text-decoration: none;
  cursor: pointer;
}

.text-block-7-copy {
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.upload-icon {
  height: 40px;
  margin-bottom: 14px;
}

.grid-double {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.single-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.single-grid.final{
    margin-bottom: 69px;
}

.full-block-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: 14px;
  padding: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: hsla(0, 0%, 64%, 0.09);
}

.choose-block {
  position: relative;
  /*height: 65px;*/
  margin-bottom: 6px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.choose-block.final {
  /*height: 48px;*/
  padding-right: 23px;
  border-bottom-style: none;
}

.choose-block._2 {
  /*height: 48px;*/
}

.choose-block.large {
  /*height: 102px;*/
}

.overzicht-heading {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
}

.full-green-button-copy {
  width: 220px;
  margin-top: 0px;
  padding: 10px 18px 9px;
  border-radius: 2px;
  background-color: hsla(0, 0%, 64%, 0.63);
  -webkit-transition: opacity 150ms ease-out, background-color 150ms ease-in;
  transition: opacity 150ms ease-out, background-color 150ms ease-in;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  background-color: #27c97a;
}

.full-green-button-copy:hover {
    background-color: #22b56e;
  color: #fff;
}

.full-green-button-copy.full {
  width: 100%;
  margin-top: 10px;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  background-color: #27c97a;
  text-align: center;
}

.fake-checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.28);
  border-radius: 2px;
  background-color: #f5f5f5;
}

.fake-checkbox.check {
  border-color: #27c97a;
  background-color: rgba(39, 201, 122, 0.18);
}

.flex-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 9px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-box.left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.relative-block-offerte {
  position: relative;
  text-align: center;
}

.relative-block-text {
  font-size: 12px;
}

.relative-block-text._2 {
  margin-top: 8px;
}

.kleur-extra {
  margin-bottom: 10px;
}

.kleur-static-block {
  position: relative;
  margin-top: 3px;
  margin-bottom: 0px;
}

.kleur-static-heading {
  display: inline-block;
  margin-right: 6px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.kleur-static-text {
  display: inline-block;
  font-size: 14px;
}

.overzicht-mini-heading {
  margin-top: 0px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.upload-text-plus-icon {
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
}

.footer-final-block {
  position: relative;
  padding: 0px 18px;
  background-color: #001427;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 20, 39, 0.98)), to(rgba(0, 20, 39, 0.98))), url('/website/images/Woodstep-Wit.svg');
  background-image: linear-gradient(180deg, rgba(0, 20, 39, 0.98), rgba(0, 20, 39, 0.98)), url('/website/images/Woodstep-Wit.svg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, 1300px;
  background-repeat: repeat, no-repeat;
}

.grid-2 {
  -ms-grid-columns: 0.33fr 1fr 0.33fr;
  grid-template-columns: 0.33fr 1fr 0.33fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.line-wrap {
  position: relative;
}

.grid-4-numbers {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.grid-5-numbers {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.grid-6-numbers {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.div-block-10 {
  margin-top: -8px;
}

.green-tekst {
  font-family: Poppins, sans-serif;
  color: #32b85e;
  font-size: 13px;
}

.circle {
  position: relative;
  z-index: 2;
  width: 22px;
  height: 22px;
  margin-right: auto;
  margin-bottom: 4px;
  margin-left: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #32b85e;
  background-image: url('/website/images/Check-white.svg');
  background-position: 50% 50%;
  background-size: 11px;
  background-repeat: no-repeat;
}

.circle._2 {
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: #a3a3a3;
  background-image: url('/website/images/Check-white.svg');
  background-position: 50% 50%;
  background-size: 11px;
}

.behind-line {
  position: relative;
  width: 516px;
  height: 0px;
  margin-top: -35px;
  margin-right: auto;
  margin-left: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #a3a3a3;
  background-color: transparent;
}
.behind-line.w20{
    border-image:   linear-gradient(to right, #32b85e 20%, #a3a3a3 20%) 1;
}
.behind-line.w25{
    border-image:   linear-gradient(to right, #32b85e 25%, #a3a3a3 25%) 1;
}
.behind-line.w33{
    border-image:   linear-gradient(to right, #32b85e 33.33%, #a3a3a3 33.33%) 1;
}
.behind-line.w40{
    border-image:   linear-gradient(to right, #32b85e 40%, #a3a3a3 40%) 1;
}
.behind-line.w50{
    border-image:   linear-gradient(to right, #32b85e 50%, #a3a3a3 50%) 1;
}
.behind-line.w60{
    border-image:   linear-gradient(to right, #32b85e 60%, #a3a3a3 60%) 1;
}
.behind-line.w66{
    border-image:   linear-gradient(to right, #32b85e 66.66%, #a3a3a3 66.66%) 1;
}
.behind-line.w75{
    border-image:   linear-gradient(to right, #32b85e 75%, #a3a3a3 75%) 1;
}
.behind-line.w80{
    border-image:   linear-gradient(to right, #32b85e 80%, #a3a3a3 80%) 1;
}
.behind-line.w100{
    border-image:   linear-gradient(to right, #32b85e 100%, #a3a3a3 100%) 1;
}

.circle-wrap {
  font-family: Poppins, sans-serif;
  color: #a3a3a3;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.relative-fixed-block-copy {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  box-shadow: 1px 7px 15px -6px rgba(0, 0, 0, 0.1);
}

.vacature-link-block {
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding: 30px 80px 30px 40px;
  border-radius: 4px;
  background-color: #fff;
  background-image: url('/website/images/right-arrow.svg');
  background-position: 96% 50%;
  background-size: 17px;
  background-repeat: no-repeat;
  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: box-shadow 200ms ease, background-position 200ms ease;
  transition: box-shadow 200ms ease, background-position 200ms ease;
  color: rgba(34, 202, 81, 0.92);
  text-decoration: none;
}

.vacature-link-block:hover {
  background-position: 97% 50%;
  box-shadow: 4px 4px 27px 0 rgba(0, 0, 0, 0.08);
  color: #9d43ff;
}

.vacature-link-block.final {
  margin-bottom: 0px;
}

.category-mini-text {
  display: inline-block;
  margin-right: 10px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.fake-tag {
  margin-right: 6px;
  margin-bottom: 11px;
  margin-left: 6px;
  padding: 6px 14px;
  border-radius: 2px;
  background-color: #b6b6b6;
  color: #fff;
  text-decoration: none;
}

.fake-tag:hover {
  background-color: #b9b9b9;
}

.fake-tag.checked {
  background-color: #ff9c1e;
}

.vacature-title {
  display: inline-block;
  margin-bottom: 8px;
  font-family: Poppins, sans-serif;
  color: #0c0f21;
  font-size: 23px;
  font-weight: 600;
}

.vacature-title.uren {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 14px;
  padding-left: 14px;
  border-left: 1px solid rgba(0, 0, 0, 0.09);
  color: rgba(0, 0, 0, 0.18);
  font-size: 19px;
  line-height: 13px;
  font-weight: 600;
}

.vacature-summary {
  margin-bottom: 0px;
  color: #001427;
  font-size: 16px;
}

.top-filter-wrapper {
  margin-bottom: 6px;
}

.tag-wrapper {
  display: inline-block;
  text-align: center;
}

.image-section-grid-wrapper {
  background-color: #fff;
}

.image-section-grid {
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.image-section-grid-image {
  width: 100%;
  height: 302px;
  -o-object-fit: cover;
  object-fit: cover;
}

.vacature-hero {
  position: relative;
  height: 630px;
}

.vacature-hero.blog {
  height: 330px;
}

.vacature-hero-overlay {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  padding: 100px 18px 45px;
  background-color: rgba(0, 20, 39, 0.93);
  background-image: linear-gradient(45deg, rgba(0, 20, 39, 0.74), transparent 59%);
}

.vacature-hero-overlay._2 {
  padding-top: 56px;
}

.vacature-hero-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 100%;
  object-position: 50% 100%;
}

.vacature-icon-wrapper {
  margin-bottom: 11px;
}

.vacature-icon-item {
  display: inline-block;
  margin-right: 16px;
}

.large-image-wrapper-vacature {
  position: relative;
  z-index: 10;
  overflow: hidden;
  margin-top: 66px;
  border-radius: 2px;
  box-shadow: 0 9px 14px -3px rgba(0, 0, 0, 0.11);
}

.large-image-wrapp {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 450px;
  -o-object-fit: cover;
  object-fit: cover;
}

.large-content-section-vacature {
  position: relative;
  padding: 264px 18px 100px;
  background-color: #f7f7f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.9%, 0.97)), to(hsla(0, 0%, 96.9%, 0.97))), url('/website/images/Woodstpe-zwart.svg');
  background-image: linear-gradient(180deg, hsla(0, 0%, 96.9%, 0.97), hsla(0, 0%, 96.9%, 0.97)), url('/website/images/Woodstpe-zwart.svg');
  background-position: 0px 0px, 150% 50%;
  background-size: auto, 1060px;
  background-repeat: repeat, no-repeat;
}

.large-content-section-vacature.offerte {
  padding-top: 70px;
}

.content-text-wrapper {
  width: 80%;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
}

.content-text-wrapper.final {
  margin-bottom: 0px;
}

.vacature-usp-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.vacature-usp-text {
  display: inline-block;
  font-size: 15px;
  white-space: nowrap;
}

.red-block,
.green-block,
.orange-block {
  display: -ms-grid;
  display: grid;
  margin-top: 38px;
  margin-bottom: 0px;
  padding: 26px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 0.25fr;
  grid-template-columns: 1fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 156, 30, 0.18);
  border-radius: 2px;
  background-color: rgba(255, 166, 54, 0.13);
  font-family: Poppins, sans-serif;
  color: #ff9c1e;
  font-size: 15px;
  font-weight: 600;
}
.green-block{
    background-color: rgba(54,255,54,0.17);
    border-color: rgba(64, 255, 64, 0.18);
    color: #00ce4d;
}
.red-block{
    background-color: rgba(255, 54, 54, 0.17);
    border-color: rgba(255, 64, 64, 0.18);
    color: #ce0000;
}
.red-block._2,
.green-block._2{
    padding: 15px;
    margin-top: 0;
}
.orange-block._2 {
  font-size: 15px;
  text-align: left;
}

.bold-link {
  color: #001427;
  text-decoration: none;
}

.right-fixed-block {
  position: fixed;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  margin-bottom: 10px;
}

.werkwijze-grid-wrapper {
  position: relative;
  z-index: 10;
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.werkwijze-grid-small {
  padding: 33px 8px 16px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 13px 13px 15px -14px rgba(0, 0, 0, 0.14);
  text-align: center;
}

.step-circle-wrap {
  text-align: center;
}

.step-circle {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  margin-right: auto;
  margin-bottom: 13px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #f1f1f1;
}

.step-circle-text {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: #981c1f;
  font-size: 17px;
}

.large-behind-line {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 1px;
  max-width: 900px;
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
  border-style: dashed;
  border-width: 0.5px;
  border-color: rgba(0, 20, 39, 0.18);
}

.large-content-wrapper {
  margin-top: 75px;
  margin-bottom: 0px;
  padding: 30px 35px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 13px 13px 15px -14px rgba(0, 0, 0, 0.14);
}

.large-content-wrapper.block {
  margin-top: 0px;
  margin-bottom: 75px;
}

.large-content-wrapper.single {
  margin-top: 0px;
  margin-bottom: 0px;
}

.usp-bar-red {
  padding: 20px 18px;
  background-color: #981c1f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(152, 28, 31, 0.93)), to(rgba(152, 28, 31, 0.93))), url('/website/images/Woodstep-Wit.svg');
  background-image: linear-gradient(180deg, rgba(152, 28, 31, 0.93), rgba(152, 28, 31, 0.93)), url('/website/images/Woodstep-Wit.svg');
  background-position: 0px 0px, 0% 50%;
  background-size: auto, 1000px;
  background-repeat: repeat, no-repeat;
}

.color-filter-grid {
  -ms-grid-columns: 0.3fr 1fr;
  grid-template-columns: 0.3fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.color-block-filter {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.color-filter-block {
  position: -webkit-sticky;
  position: sticky;
  top: 130px;
  margin-bottom: 0px;
  padding: 15px 21px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 3px 11px -6px rgba(0, 0, 0, 0.32);
}

.color-filter-heading {
  margin-top: 0px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.large-color-grid {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.large-color-link-block-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 120px;
  border-radius: 2px;
}

.large-link-block-image {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.popup-overlay {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999999;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 20, 39, 0.88);
  opacity: 0;
}

.color-block-popup,
.modal-block-popup {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  width: 500px;
  max-width: 500px;
  padding: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: #fff;
}
.color-block-popup{
    width: auto;
    max-width: 100%;
}


.color-block-popup._2 {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.small-usp-block {
  margin-bottom: 0px;
}

.usp-link-block-green-small {
  margin-right: 0px;
  margin-bottom: 3px;
  margin-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #000;
  text-decoration: none;
}

.usp-link-block-green-small.final {
  margin-bottom: 0px;
}

.usp-link-text-small {
  display: inline-block;
  font-family: Poppins, sans-serif;
  color: #0c0f21;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.large-item-kleur {
  width: 100%;
  /*height: 150px;*/
  margin-bottom: 11px;
  border-radius: 2px;
  -o-object-fit: cover;
  object-fit: cover;
}

.cross {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 0px;
  padding: 7px;
  border-bottom-left-radius: 2px;
  background-color: #fff;
}

.cross-cancel {
  width: 20px;
  height: 20px;
}

.full-link-block {
  display: block;
  margin-bottom: 9px;
  color: #001427;
  text-decoration: none;
}

.before-after-grid {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.gegevens-wijzigen-popup {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 99999;
    display: none;
    width: 100vw;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 20, 39, 0.75);
}
.large-image-text-2.gegevens {
    width: 600px;
    max-width: 600px;
    padding-top: 35px;
    padding-bottom: 35px;
}
.large-image-text-2 {
    position: relative;
    z-index: 100;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 35px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: -3px 18px 50px -14px rgba(0, 0, 0, 0.14);
}
.usp-heading-wrapper-2 {
    max-width: 590px;
    margin-bottom: 20px;
    color: #0c0f21;
    text-align: left;
}
.gegevens-grid {
    margin-bottom: 12px;
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.icon-button-orange {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 43px;
    margin-left: 0px;
    padding: 6px 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    background-color: #ff9c1e;
    text-decoration: none;
}
.basic-section-heading-item.smaller {
    font-size: 22px;
}
.shop-content-usp {
    position: relative;
    display: block;
    margin-top: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.usp-list-2 {
    display: block;
    margin-right: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 22px;
    background-image: url('/website/images/001-check.svg');
    background-position: 0% 49%;
    background-size: 15px;
    background-repeat: no-repeat;
    font-family: Poppins, sans-serif;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.usp-list-2._2 {
    margin-right: 0px;
    font-weight: 500;
}

@media screen and (max-width: 1440px) {
    .large-slide-bot-block {
        padding: 0 12px;
    }
}
@media screen and (max-width: 991px) {
  .basic-3-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .relative-block.responsive-hide {
    display: none;
  }

  .top-bar-link-text {
    display: inline;
  }

  .menu-text {
    font-size: 14px;
  }

  .nav-link-wrapper {
    display: block;
    height: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding: 14px 14px 14px 0px;
    border-bottom: 1px none rgba(149, 145, 170, 0.13);
    color: #fff;
    font-size: 15px;
  }

  .nav-link-wrapper:hover {
    color: #fff;
  }

  .nav-link-wrapper.w--current {
    color: #a798f5;
  }

  .logo {
    margin-top: 0px;
    margin-left: 0px;
  }

  .two-grid-menu-copy {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .two-grid-menu-block {
    padding-bottom: 77px;
  }

  .dropdown-list-assortiment.w--open {
    padding-right: 0px;
  }

  .menu-button {
    padding: 14px;
    float: right;
  }

  .nav-link-title {
    font-size: 14px;
  }

  .final-menu-item-link._2-line {
    background-position: 0% 17%;
  }

  .two-grid-menu-block-right.hide-menu-item {
    display: none;
  }

  .webshop-dropdown-toggle {
    float: right;
  }

  .small-menu-heading {
    font-size: 16px;
  }

  .hero-image-block-overlay {
    padding-top: 67px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .hero-title-copy {
    font-size: 32px;
    line-height: 32px;
  }

  .offerte-usp-heading-copy {
    font-size: 19px;
  }

  .offerte-new {
    position: relative;
    max-width: 420px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
  }

  .offerte-block-stap-2 {
    margin-top: 0px;
  }

  .offerte-block-stap-2._0 {
    margin-top: -34px;
  }

  .small-hero-heading-wrapper {
    margin-top: 0px;
    /*text-align: center;*/
  }

  .offerte-block {
    margin-top: 0px;
  }

  .div-block-15 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
    .div-block-15.no-driving-costs {
        -webkit-box-align: end;
        -webkit-align-items: end;
        -ms-flex-align: end;
        align-items: end;
    }

  .intro-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .basic-quad,
  .basic-triple {
    grid-row-gap: 50px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  ._2-col-grid {
    -ms-grid-columns: 0.75fr 0.5fr;
    grid-template-columns: 0.75fr 0.5fr;
  }
  ._2-col-grid.advies {
    -ms-grid-columns: 0.5fr 0.5fr;
    grid-template-columns: 0.5fr 0.5fr;
  }

  .large-image-text.klanten {
    padding: 24px;
  }

  .section-tint {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .green-span {
    font-size: 18px;
    text-align: right;
  }

  .green-span-small {
    display: block;
    float: none;
  }

  .category-grid-wrapper {
    display: block;
  }

  .project-mini-heading {
    font-size: 14px;
  }

  .assortiment-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .category-grid {
    display: block;
  }

  .deal-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  ._2-col-aanbieding {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .image-section-overlay {
    padding-bottom: 50px;
  }

  .bot {
    display: none;
  }

  .footer-block-left._1 {
    left: 0px;
  }

  .usp-link-block.hide {
    display: none;
  }

  .coming-soon-tag {
    top: -2px;
    margin-left: 6px;
    padding-top: 0px;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 5px;
    line-height: 13px;
  }

  .final-footer-divider {
    padding-right: 18px;
    padding-left: 18px;
  }

  .usp-grid-large {
    -ms-grid-columns: 0.75fr 1fr;
    grid-template-columns: 0.75fr 1fr;
  }

  .footer-grid-new {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .bedankt-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .small-hero-button-wrapper.center {
    text-align: center;
  }

  .breadcrumb-wrapper {
    position: relative;
    margin-right: 0px;
    margin-bottom: 9px;
    text-align: center;
  }

  .new-heading-wrapper {
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  .category-image-overlay-new {
    height: 100%;
  }

  /*.icon-wrapper-mini {*/
  /*  margin-right: 20px;*/
  /*}*/

  .large-content-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .table-block {
    padding-right: 6px;
    padding-left: 6px;
  }

  .slider-section-slider {
    height: 350px;
  }

  .werkgebied-top-grid,
  .klantenservice-top-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .right-col-grid-showroom {
    grid-column-gap: 10px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
    box-shadow: none;
  }

  .klantenservice-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .icon-wrapper-klantenservice {
    margin-right: 20px;
  }

  .triple-grid-veelgestelde-vragen {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .icon-wrapper-klantenservice-small {
    margin-right: 20px;
  }

  .grid-vragen {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .icon-wrapper-klantenservice-mini {
    margin-right: 20px;
  }

  .left-mini-klantenservice {
    margin-top: 0px;
  }

  .klantenservice-antwoord-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .paragraph-grid {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .div-block {
    padding-right: 0px;
  }

  .combi-deal-4 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .social-blog-wrapper {
    padding-top: 0px;
    padding-left: 0px;
  }

  .social-blog-item {
    margin-right: 0px;
  }

  .large-content-section-blog {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .blog-post-wrapper {
    max-width: 100%;
    margin-top: -130px;
    padding-right: 43px;
    padding-left: 43px;
  }

  .full-section {
    padding-right: 18px;
    padding-left: 18px;
  }

  .houtsoort-grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .houtsoort-image {
    height: 120px;
  }

  .double-grid-block {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .right-block-wrap {
    padding: 20px;
  }

  .houtsoort-grid-copy {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .double-grid {
    -ms-grid-columns: 0.25fr 1fr;
    grid-template-columns: 0.25fr 1fr;
  }

  .houtsoort-image-2 {
    height: 120px;
  }

  .plint-image {
    height: 120px;
  }

  .question-answer {
    z-index: 10;
    display: block;
    width: 250px;
  }

  /*.upload-field {*/
  /*  padding-top: 34px;*/
  /*  padding-bottom: 34px;*/
  /*}*/

  .grid-double {
    margin-bottom: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .choose-block.final {
    height: auto;
  }

  /*.choose-block._2 {*/
  /*  height: 42px;*/
  /*}*/

  .choose-block.large {
    height: auto;
  }

  .grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .quad-grid-numbers {
    max-width: 640px;
  }

  .div-block-10.hide {
    display: none;
  }

  .relative-fixed-block-copy {
    padding: 11px 18px;
  }

  .vacature-link-block {
    margin-bottom: 15px;
  }

  .vacature-title {
    font-size: 22px;
  }

  .vacature-hero-overlay {
    height: 100%;
    padding-top: 50px;
  }

  .large-image-wrapper-vacature {
    margin-top: 40px;
  }

  .large-image-wrapp {
    height: 360px;
  }

  .large-content-section-vacature {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .content-text-wrapper {
    width: 90%;
  }

  .vacature-usp-text {
    white-space: normal;
  }

  .green-block,
  .orange-block {
    -ms-grid-columns: 0.75fr 0.33fr;
    grid-template-columns: 0.75fr 0.33fr;
  }

  .werkwijze-grid-wrapper {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .large-behind-line {
    display: none;
  }

  .color-filter-grid {
    -ms-grid-columns: 0.4fr 1fr;
    grid-template-columns: 0.4fr 1fr;
  }

  .large-color-grid {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .popup-overlay {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
  }

  .color-block-popup {
    padding: 20px;
  }
  .main-blog-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  .main-blog-grid .large-image-item {
    margin-bottom: 32px;
  }
  .w-layout-grid._2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .basic-3-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .trustpilot-link-white {
    width: 100%;
    text-decoration: none;
  }

  .top-bar-link-text {
    font-size: 12px;
  }

  .final-footer-text.desktop {
    display: none;
  }

  .nav-link-wrapper {
    width: 100%;
    padding: 14px 18px 14px 0px;
  }

  .nav-link-wrapper.w--open {
    border-bottom: 0px none transparent;
  }

  .logo {
    width: 130px;
    max-width: none;
  }

  .two-grid-menu-copy {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .two-grid-menu {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .two-grid-menu-block {
    margin-bottom: 6px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .two-grid-menu-block.final {
    margin-bottom: 0px;
  }

  .right-divider-wrap {
    display: none;
  }

  .dropdown-list-assortiment.w--open {
    margin-top: 105px;
    padding-top: 14px;
    padding-bottom: 14px;
    box-shadow: none;
  }

  .brand-logo {
    padding-left: 0px;
  }

  .dropdown-icon-dark {
    display: none;
    color: #0c0f21;
    font-size: 13px;
  }

  .final-menu-item-link._2-line {
    position: relative;
    bottom: 0px;
  }

  .two-grid-menu-block-right {
    display: none;
  }

  .dropdown {
    width: 100%;
    border-bottom: 1px none rgba(0, 0, 0, 0.12);
  }

  .navbar-new {
    padding-right: 8px;
    padding-left: 8px;
  }

  .hero-title-copy {
    font-size: 30px;
  }

  .placeholder-tekst {
    font-size: 12px;
  }

  .service-heading {
    font-size: 20px;
  }

  /*.small-hero-heading-wrapper {*/
  /*  text-align: center;*/
  /*}*/

  .usp-list {
    margin-right: 10px;
    margin-left: 10px;
  }

  .fake-button {
    font-size: 12px;
  }

  .large-slide-bot-block {
    padding-right: 16px;
    padding-left: 16px;
  }

  .cta-section-text {
    font-size: 20px;
  }

  .section-image-copy {
    height: 300px;
  }

  .button-gtid {
    max-width: none;
    margin-left: 0px;
  }

  ._2-grid-block {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .showroom-text-block {
    max-width: 100%;
    margin-bottom: 14px;
  }

  .section-tint {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .triple-grid-review {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .large-woodstep-slider {
    height: 300px;
  }

  .green-span-small {
    display: block;
  }

  .usp-heading-wrapper-copy {
    margin-bottom: 14px;
  }

  .project-mini-heading {
    font-size: 17px;
  }

  .combi-deal-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .div-block-5 {
    margin-top: 0px;
  }

  ._2-col-aanbieding {
    -ms-grid-columns: 1fr 0.5fr;
    grid-template-columns: 1fr 0.5fr;
  }

  .image-section-overlay {
    padding-bottom: 151px;
  }

  .footer-block-left {
    padding-right: 0px;
    padding-left: 0px;
  }

  .usp-grid._2 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer-link {
    display: inline-block;
    margin-right: 13px;
  }

  .coming-soon-tag {
    display: none;
    font-size: 8px;
  }

  .footer {
    padding-top: 35px;
    padding-bottom: 50px;
  }
  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72a0-d5ae729d{
      margin-bottom: 15px;
  }

  .footer-title {
    margin-bottom: 10px;
  }

  .usp-grid-large {
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer-block {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .usp-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .footer-grid-new {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .bedankt-hero-wwrapper {
    text-align: left;
  }

  .bedankt-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .breadcrumb-wrapper {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  .new-heading-wrapper {
    text-align: center;
  }

  .subpage-title {
    font-size: 30px;
  }

  .category-image-overlay-new {
    padding-top: 46px;
    padding-bottom: 47px;
  }

  .content-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .content-grid.reverse {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .large-image-wrapper-cat.small-image {
    height: 250px;
  }

  .grid-triple-usp-large {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .text-blok-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .large-table-wrapper {
    overflow: visible;
  }

  .step-block {
    font-size: 11px;
  }

  .werkwijze-block {
    max-width: 100%;
    margin-bottom: 14px;
  }

  .triple-grid-veelgestelde-vragen {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-vragen {
    margin-top: 0px;
  }

  .paragraph-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .final-blog-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .time-right-top-mini {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .blog-post-wrapper {
    padding: 25px 35px;
  }

  .houtsoort-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .step-heading {
    margin-top: 6px;
    margin-bottom: 14px;
  }

  .houtsoort-grid-copy {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .choose-block-simple {
    height: auto;
  }

  .grid-double {
    margin-bottom: 0px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .choose-block {
    height: auto;
  }

  .choose-block.final {
    border-bottom-style: solid;
  }

  .footer-final-block {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .behind-line {
    width: 450px;
    margin-top: -37px;
  }

  .vacature-link-block {
    padding-right: 35px;
    padding-left: 35px;
    background-image: none;
    text-align: center;
  }

  .vacature-title {
    display: block;
  }

  .vacature-title.uren {
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px;
    border-left-width: 0px;
    line-height: 18px;
  }

  .top-filter-wrapper {
    text-align: center;
  }

  .vacature-hero-overlay {
    padding-top: 46px;
    padding-bottom: 47px;
  }

  .large-image-wrapper-vacature {
    margin-top: 49px;
  }

  .large-image-wrapp {
    height: 321px;
  }

  .content-text-wrapper {
    width: 100%;
  }

  .green-block,
  .orange-block {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .werkwijze-grid-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .color-filter-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .color-block-filter {
    position: relative;
  }

  .color-filter-block {
    position: relative;
    top: 0px;
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  .shop-content-usp {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .usp-list-2 {
    margin-right: 0px;
    margin-left: 0px;
    font-size: 13px;
  }
  ._2-col-grid.advies {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .top-bar-link-wrapper.hiode {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    font-size: 25px;
  }

  .basic-3-grid {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .trustpilot-link-white {
    display: inline-block;
  }

  .trustpilot-logo {
    top: -2px;
    width: 70px;
  }

  .top-bar-link-text {
    font-size: 11px;
  }

  .top-bar-link-icon {
    width: 16px;
  }

  .nav-link-wrapper {
      display: none;
  }

  .logo {
    height: 30px;
    margin-top: 0;
    margin-left: 0;
  }

  .dropdown-list-assortiment.relatibe.w--open {
    position: relative;
    margin-top: 0px;
    background-color: #fff;
  }

  .menu-button {
    padding-top: 13px;
    padding-bottom: 13px;
    background-color: #f5f5f5;
  }

  .menu-button.w--open {
    background-color: #f8f8f8;
    color: #001427;
  }

  .nav-link-title {
    font-size: 13px;
  }

  .dropdown-icon-dark {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    margin-top: 106px;
    background-color: #f8f8f8;
    text-align: left;
    max-height: ~"calc(100vh - 106px)";;
    overflow-y: auto;
    overflow-x: hidden;
  }


  .webshop-dropdown-toggle {
    height: 100%;
    padding-right: 20px;
    float: none;
    color: #ffffff;
    white-space: nowrap;
  }

  .nav-link-block {
    width: 100%;
    padding-left: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .nav-link-block.final {
    border-bottom:none;
  }

  .hero-image-block-overlay {
    padding-top: 33px;
  }

  .total-form-mini-block {
    padding-right: 0;
    padding-left: 0;
  }

  .icon {
    margin-right: 5px;
  }

  .offerte-usp-heading-copy {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 18px;
  }

  .final-price-wrapp {
    padding-left: 16px;
    text-align: left;
  }

  .placeholder-tekst {
    font-size: 14px;
  }

  .placeholder-tekst._2 {
    font-size: 9px;
  }

  .service-heading {
    font-size: 16px;
  }

  .offerte-block-stap-2 {
    position: initial;
    height: auto;
    padding: 30px 28px;
  }

  .calc-footer {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right;
  }

  .small-hero-heading-wrapper {
    max-width: none;
  }

  .small-offerte-block {
    margin-bottom: 6px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .offerte-block {
    height: auto;
    padding: 30px 25px;
  }

  .div-block-15 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .div-block-15.no-driving-costs {
      -webkit-box-align: end;
      -webkit-align-items: end;
      -ms-flex-align: end;
      align-items: end;
  }
  .usp-list {
    margin-bottom: 6px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-position: 0% 11%;
  }

  .basic-showroom .large-image-item,
  .large-image-item {
    height: 390px;
  }

  .intro-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .basic-showroom,
  .basic-triple,
  .basic-quad {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .large-slide-bot-block {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
  }

  .basic-section-heading {
    font-size: 28px;
  }

  ._2-col-grid {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .section-image-copy {
    height: 280px;
  }

  .large-image-text.klanten {
    padding-right: 15px;
    padding-left: 15px;
  }

  .section-tint {
    padding-right: 18px;
    padding-left: 18px;
  }

  .large-woodstep-slider {
    height: 280px;
  }

  .large-paragraph {
    font-size: 15px;
  }

  .project-mini-heading {
    font-size: 15px;
  }

  .mini-usp-heading-paragraph {
    font-size: 15px;
  }

  ._2-col-aanbieding {
    -ms-grid-columns: 1fr 0.75fr;
    grid-template-columns: 1fr 0.75fr;
  }

  .image-section-overlay {
    padding-top: 54px;
    padding-bottom: 118px;
  }

  .usp-grid._2 {
    display: block;
    text-align: center;
  }

  .footer-link {
    margin-top: 7px;
  }

  .footer {
    padding-bottom: 69px;
  }

  .final-footer-divider.odd {
    padding: 0px;
    border-top-style: none;
  }

  .footer-title {
    font-size: 17px;
  }

  .usp-grid-large {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .basic-2-grid {
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .hero-sub-heading {
    font-size: 16px;
    line-height: 25px;
  }

  .dark-title {
    font-size: 30px;
    line-height: 40px;
  }

  .bedankt-hero-wwrapper {
    max-width: none;
  }

  .bedankt-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .new-heading-wrapper {
    max-width: none;
  }

  .subpage-title {
    font-size: 23px;
    line-height: 34px;
  }

  .breadcrumb-text {
    display: inline-block;
  }

  .large-content-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .content-grid {
    margin-bottom: 30px;
  }

  .large-image-wrapper-cat {
    height: 240px;
  }

  .large-image-wrapper-cat.small-image {
    height: 210px;
  }

  .large-table-top-item {
    position: relative;
  }

  .large-table-top-item.extra {
    width: 100vw;
  }

  .large-table-wrapper {
    max-width: 100%;
    margin-left: -18px;
  }

  .table-block.first.final {
    border-right-style: solid;
  }

  .table-block._4 {
    width: 25vw;
  }

  .small-check {
    margin-top: 3px;
  }

  .table-top-block._4 {
    width: 25vw;
  }
  .table-top-block._5 {
    width: 20vw;
  }

  .table-left-heading {
    font-size: 11px;
  }

  .step-block {
    position: relative;
    margin-bottom: 8px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .step-block._2 {
    position: absolute;
  }

  .right-col-grid-showroom {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .hero-image-block-wrapper {
    height: 180px;
  }

  .klantenservice-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .basic-white-block {
    padding-right: 20px;
    padding-left: 20px;
  }

  .faq-main-item-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }

  .klantenservice-block {
    padding-right: 20px;
    padding-left: 20px;
  }

  .search-bar {
    padding-right: 20px;
    padding-left: 20px;
  }

  .grid-vragen {
    margin-top: 0px;
  }

  .left-mini-klantenservice {
    padding-right: 20px;
    padding-left: 20px;
  }

  .klantenservice-antwoord-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section-heading-left {
    font-size: 28px;
  }

  .highlight-heading {
    font-size: 21px;
  }

  .combi-deal-4 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .final-blog-block-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .final-blog-item {
    padding-left: 0px;
  }

  .large-content-section-blog {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .blog-post-wrapper {
    margin-top: -116px;
    padding-right: 21px;
    padding-left: 21px;
  }

  .select-heading-wrap {
    position: relative;
  }

  .small-kleur-heading {
    font-size: 20px;
  }

  .step-heading-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .large-item {
    height: 160px;
  }

  .right-block-wrap {
    font-size: 14px;
  }

  .right-block-wrap._2 {
    padding: 14px;
  }

  .large-offerte-block {
    padding: 12px;
  }

  .color-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .small-color-item {
    height: 59px;
  }

  .form-item::-webkit-input-placeholder {
    font-size: 11px;
  }

  .form-item:-ms-input-placeholder {
    font-size: 11px;
  }

  .form-item::-ms-input-placeholder {
    font-size: 11px;
  }

  .form-item::placeholder {
    font-size: 11px;
  }

  .double-grid {
    grid-column-gap: 6px;
  }

  .question-answer {
    width: 220px;
  }

  ._6-grid {
    grid-row-gap: 8px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .upload-field {
    text-align: center;
  }

  .footer-final-block {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .grid-2 {
    -ms-grid-columns: 0.75fr;
    grid-template-columns: 0.75fr;
  }

  .green-tekst {
    font-size: 9px;
  }

  .behind-line {
    width: 300px;
  }

  .circle-wrap {
    font-size: 9px;
  }

  .vacature-link-block {
    padding: 20px;
    background-image: none;
  }

  .category-mini-text {
    margin-bottom: 12px;
  }

  .vacature-title {
    font-size: 20px;
    line-height: 23px;
  }

  .vacature-title.uren {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 16px;
  }

  .vacature-summary {
    font-size: 13px;
    line-height: 19px;
  }

  .image-section-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .image-section-grid-image {
    height: 190px;
  }

  .vacature-hero {
    height: auto;
  }

  .large-image-wrapper-vacature {
    margin-top: 0px;
  }

  .large-image-wrapp {
    margin-top: 35px;
  }

  .large-content-section-vacature {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .vacature-usp-block {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .large-content-wrapper {
    padding: 15px;
  }

  .color-block-filter {
    position: relative;
  }

  .color-filter-block {
    grid-row-gap: 8px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .large-color-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .popup-overlay {
    display: none;
    padding-right: 10px;
    padding-left: 10px;
    opacity: 0;
  }

  .color-block-popup {
    font-size: 14px;
  }

  .color-block-popup._2 {
    padding: 14px;
  }

  /*.large-item-kleur {*/
  /*  height: 160px;*/
  /*}*/
  .color-block-popup,
  .large-image-text-2.gegevens,
  .modal-advies-popup,
  .modal-block-popup {
    width: 98vw;
  }
  .line-button:first-of-type {
    margin-bottom: 10px;
  }
  .combi-deal-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .shop-content-usp {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

#w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a43862224-7df3335d {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
}

.total-form-large-block {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  display: grid;
}

.gegevens-hidden-grid {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

#w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a43862252-7df3335d {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
}

#w-node-_771e707c-a588-52d6-274c-6a481b9315ab-7df3335d {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_771e707c-a588-52d6-274c-6a481b9315c6-7df3335d {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_771e707c-a588-52d6-274c-6a481b9315e1-7df3335d {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72a0-d5ae729d {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72cb-d5ae729d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72d0-d5ae729d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72d5-d5ae729d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72da-d5ae729d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72df-d5ae729d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72e4-d5ae729d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72e9-d5ae729d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72eb-d5ae729d {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72fd-d5ae729d {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae7315-d5ae729d {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae7333-d5ae729d {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae734e-d5ae729d {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cadf-0c34cab8 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
}

#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cb00-0c34cab8 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cb0d-0c34cab8 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
}

#w-node-_3e304650-2751-4095-b6e8-2c82ee088577-ee08856b {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
#w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a43862209-7df3335d,
#w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a4386220f-7df3335d,
#w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a43862211-7df3335d,
#w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a4386221c-7df3335d,
#w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a4386221e-7df3335d,
#w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a4386224a-7df3335d,
#w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a4386224c-7df3335d,
#w-node-e94c8aaf-78ff-8a44-369e-f435203dd1d8-7df3335d,
#w-node-e94c8aaf-78ff-8a44-369e-f435203dd1e3-7df3335d,
#w-node-_771e707c-a588-52d6-274c-6a481b931593-7df3335d,
#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cac4-0c34cab8,
#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34caca-0c34cab8,
#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cacc-0c34cab8,
#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cad7-0c34cab8,
#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cad9-0c34cab8,
#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cb05-0c34cab8,
#w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cb07-0c34cab8,
#w-node-_4b57b011-29ee-9b3c-5c8b-d36279807665-79807661 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4b57b011-29ee-9b3c-5c8b-d3627980766d-79807661 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4b57b011-29ee-9b3c-5c8b-d36279807672-79807661 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_4b57b011-29ee-9b3c-5c8b-d36279807681-79807661 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4b57b011-29ee-9b3c-5c8b-d362798076db-79807661 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_9e8ed1e6-33b5-1e26-fca6-b9685a7bdfac-6bc2c28b {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c14-e5107b17 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c21-e5107b17 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-e5107b17 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-e5107b17 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c3a-e5107b17 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c47-e5107b17 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c4b-e5107b17 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c58-e5107b17 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_2e9d4802-4077-7f54-cec6-1d2fae1a6079-e5107b17 {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_2e9d4802-4077-7f54-cec6-1d2fae1a6083-e5107b17 {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_2e9d4802-4077-7f54-cec6-1d2fae1a6079-5eb0ba82 {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_2e9d4802-4077-7f54-cec6-1d2fae1a6083-5eb0ba82 {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c14-06d37ac5 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_1dcb1f43-696e-f1be-9ea9-b5cd9927494a-06d37ac5 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-06d37ac5 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-edd386ee-3c20-d870-e1e8-75cded529d96-06d37ac5 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-edd386ee-3c20-d870-e1e8-75cded529da3-06d37ac5 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_1f9e6468-8df4-267d-41ac-b9c5265af4fb-06d37ac5 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_235391f1-e38b-d94b-4400-3933ad6b839c-06d37ac5 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3d07fece-c4a0-3a64-3633-e07d04577e7a-06d37ac5 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3d07fece-c4a0-3a64-3633-e07d04577e87-06d37ac5 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_7f47237c-c84b-80bc-93e4-58ddf91fea0a-06d37ac5 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-bf673d4d-31f0-6b37-622d-97fd83e57e18-06d37ac5 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-bf673d4d-31f0-6b37-622d-97fd83e57e25-06d37ac5 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_33326e86-17e6-9aeb-874b-42ba635e44bd-c6907912 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-bf966b18-9026-83e9-ac41-7ee135728269-c6907912 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-bf966b18-9026-83e9-ac41-7ee13572826e-c6907912 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-bf966b18-9026-83e9-ac41-7ee135728273-c6907912 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-bf966b18-9026-83e9-ac41-7ee135728278-c6907912 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-bf966b18-9026-83e9-ac41-7ee13572827d-c6907912 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-bf966b18-9026-83e9-ac41-7ee135728282-c6907912 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-bf966b18-9026-83e9-ac41-7ee135728287-c6907912 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_6ed77934-d6f9-d661-d814-f46cb9d151e5-c6907912 {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_6dcc5fb8-b24b-55da-afd6-db6e58017ae7-c6907912 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-bd7c206c-f4b9-8943-86ee-422154da4a8d-c6907912 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_46377fd2-3a61-cacf-ca35-c127c2ef336e-c6907912 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-be355935-3e03-0887-e4d2-9cf892c37aa5-c6907912 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-f937bc60-2d28-cf18-b39c-7e2a2e577ce6-c6907912 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_6717d2c2-a759-734f-effe-6274e71a9ea0-c6907912 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-aa76326e-26bb-2485-2a41-193dd390c27b-34d7f279 {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-fd3d8cad-a6f4-afe4-d14f-250e1f0228fd-34d7f279 {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_71b72d8c-9fa5-5b68-0021-46b73201fed0-34d7f279 {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-f18a5a31-0a61-61c6-6423-7a338b683cb7-34d7f279 {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ecd30889-fbfc-0cc8-b864-d575cf6bb150-34d7f279 {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_4861038b-0f4d-9833-32e7-02ba424aa795-34d7f279 {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-aa76326e-26bb-2485-2a41-193dd390c27b-acdc9d04 {
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_6e9a4e63-bedc-3e66-94f5-8713244c2f2c-acdc9d04 {
  -ms-grid-row-align: start;
  align-self: start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_6d291be4-cdd1-a3aa-cebe-cd06c556520c-308e25f3 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_6d291be4-cdd1-a3aa-cebe-cd06c5565227-308e25f3 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_6d291be4-cdd1-a3aa-cebe-cd06c5565242-308e25f3 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0a724e06-09c9-eb69-b091-b8f3f4a232ad-308e25f3 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_4514789b-48db-e171-9bb3-4c75f15ea034-bf88c4ed {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-b1f8d429-cf3f-373f-25b0-d0adc54fa172-bf88c4ed {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-ac0bc11b-db46-2ea1-282e-ff8ad3be23d7-bf88c4ed {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-db36d6b4-39a8-51ea-2a56-ed848e77d0ad-bf88c4ed {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_1a2c9262-92ee-7ff4-fc54-7a58732d461f-bf88c4ed {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4514789b-48db-e171-9bb3-4c75f15ea034-d1e4511c {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-b1f8d429-cf3f-373f-25b0-d0adc54fa172-d1e4511c {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-ac0bc11b-db46-2ea1-282e-ff8ad3be23d7-d1e4511c {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_73deee17-90f2-9a41-64b3-1175959cb2d5-d85cb121 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-cc4074d1-9e37-4883-9733-1cb9ee7bc87f-d85cb121 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6d8e74e5-1f80-dc03-eed8-d88d568c2a55-568c2a53 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_6d8e74e5-1f80-dc03-eed8-d88d568c2a56-568c2a53 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_6d8e74e5-1f80-dc03-eed8-d88d568c2a57-568c2a53 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_6d8e74e5-1f80-dc03-eed8-d88d568c2a58-568c2a53 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_6d8e74e5-1f80-dc03-eed8-d88d568c2a59-568c2a53 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_6d8e74e5-1f80-dc03-eed8-d88d568c2a5a-568c2a53 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_74a920a8-4396-1422-dc72-17f42ba22f87-6791a3b0 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_8da85cc6-78f9-e0c7-4587-c4cdb4daa301-6791a3b0 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-d760ef69-7202-7857-8f6e-23ae268ec896-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-b9361a12-97a0-8a64-26f3-69ec991750c7-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_9928b735-0380-a644-b984-187802b88b4a-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_790be861-8aca-f297-b43d-d4e358d00b72-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_430d5145-dca7-bebf-84e5-6903312ec8a7-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_2f2a350a-c640-8837-6a8f-cd7cce7efee2-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-df7f264c-91ae-a6e4-d581-aa5e4c6fbcef-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-f2c484f9-c06d-1341-3e60-3e65b4d3b0a8-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_57b844f9-f6f9-6aef-d392-7bedd2cf7de1-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_3009e64a-3892-956e-574d-a0c73c45e701-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_2086ee47-10e4-ae53-9fc4-74ba06271835-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-b262845f-12f6-e166-1bd5-480e263e609c-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_0feb221f-f9e5-7c6b-388d-ad78bc5ef70d-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_7daf75dd-3f97-5157-40ae-8350b137e742-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_5034ab8a-4b71-4639-3959-2e75c36fd643-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_0f9dfc9c-20cd-4881-ce14-68e6fe1f29de-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-f5a0f95b-54b4-cfc8-69a3-8556086447bd-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-dab52b08-7ae1-4071-cd20-56546ce2a59c-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_129a5b95-5af0-1bad-7e0d-c7f5c3ddbc25-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_26f5cafe-63eb-f6e9-6f67-156e48d1018b-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_33c620da-ebb5-9812-0302-17b88029ea2c-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_549d597a-b7be-622d-79c0-6340047e44d6-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-b242cd1a-6b45-4d12-cec6-ab0fefe09a2c-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_1bab45a0-584e-326d-79cc-23238243401a-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_580cea84-d02f-7832-560c-ef1d4f872d61-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-b819be3e-b035-e785-06f0-f1fbd0d71dd2-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_34a854aa-4d06-42bf-bf0f-863e9541ce19-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-c55c5d96-6a1d-41b4-bbaa-2a88929368e5-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-d1910b84-103c-25fe-b618-9be88cf53934-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_75f421b9-31b6-aeb4-c785-730f2862f6d3-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-_1e5f4787-36c1-774a-151a-90191d0b50ae-6791a3b0 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

#w-node-a029d630-2804-c8ee-1c3e-aece3dac1212-6791a3b0 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-a029d630-2804-c8ee-1c3e-aece3dac121f-6791a3b0 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fbc72c1c-e446-5690-876f-61cac0e8d22d-6791a3b0 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c678dc40-aedb-3e5d-6797-ef02f1645827-6791a3b0 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_43e70c74-a09a-bd64-d0db-39e167d042d9-15d183d7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c14-bbfcced8 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c21-bbfcced8 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-bbfcced8 {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-bbfcced8 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c3a-bbfcced8 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c47-bbfcced8 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_2e9d4802-4077-7f54-cec6-1d2fae1a6079-bbfcced8 {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_2e9d4802-4077-7f54-cec6-1d2fae1a6083-bbfcced8 {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-828706ac {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-828706ac {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_1f2629cf-b4d9-7518-0665-f9c29c86520e-828706ac {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_1f2629cf-b4d9-7518-0665-f9c29c865218-828706ac {
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_44cb7e81-a9e1-73b5-a5d7-f2bb889fc191-828706ac {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_6f4daee2-9515-a0f4-8acb-8d9d4906af0e-828706ac {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-16894e51 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-16894e51 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_44cb7e81-a9e1-73b5-a5d7-f2bb889fc191-16894e51 {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_6f4daee2-9515-a0f4-8acb-8d9d4906af0e-16894e51 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fa63c3f9-4738-b9f8-fed4-f573d9c841d1-16894e51 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6913ca39-7513-a605-1aa8-84e2ce5bb061-16894e51 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-ba661f90 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-ba661f90 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_44cb7e81-a9e1-73b5-a5d7-f2bb889fc191-ba661f90 {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_6f4daee2-9515-a0f4-8acb-8d9d4906af0e-ba661f90 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_993e8e07-b40c-824f-7693-1e9516609221-ba661f90 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_2582778f-5660-9d82-ae49-3d9e13e99c0d-ba661f90 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_191c9af5-e868-c20f-dc68-0cd546abdb18-ba661f90 {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c14-844c5ebf {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c21-844c5ebf {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-844c5ebf {
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-844c5ebf {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c3a-844c5ebf {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c47-844c5ebf {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-28928fa0 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-28928fa0 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6f4daee2-9515-a0f4-8acb-8d9d4906af0e-28928fa0 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_38232dc5-0753-6847-3201-f96e48928390-28928fa0 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c6d8db5c-93c7-930c-22b2-8795a4dcb033-28928fa0 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c6d8db5c-93c7-930c-22b2-8795a4dcb040-28928fa0 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-78e40e7f {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-78e40e7f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6f4daee2-9515-a0f4-8acb-8d9d4906af0e-78e40e7f {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_38232dc5-0753-6847-3201-f96e48928390-78e40e7f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c6d8db5c-93c7-930c-22b2-8795a4dcb033-78e40e7f {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c6d8db5c-93c7-930c-22b2-8795a4dcb040-78e40e7f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c25-eb52c4f2 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-eb52c4f2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6f4daee2-9515-a0f4-8acb-8d9d4906af0e-eb52c4f2 {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_38232dc5-0753-6847-3201-f96e48928390-eb52c4f2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c6d8db5c-93c7-930c-22b2-8795a4dcb033-eb52c4f2 {
  -ms-grid-column-align: auto;
  justify-self: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c6d8db5c-93c7-930c-22b2-8795a4dcb040-eb52c4f2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_6d17637b-8614-edf5-6389-f5ba7e3113eb-6791a3b0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
}
#w-node-_93c035ca-2487-c9df-e535-42dfb9f3fbb5-6791a3b0 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
}
#w-node-_0f8c2a24-a5df-0b73-1e75-42c2754ea4dd-6791a3b0,
#w-node-_0f8c2a24-a5df-0b73-1e75-42c2754ea4dd-6791a3b1,
#w-node-_0f8c2a24-a5df-0b73-1e75-42c2754ea4dd-6791a3b2 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
}
@media screen and (max-width: 991px) {
  #w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a438621f4-7df3335d {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a43862201-7df3335d {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-e94c8aaf-78ff-8a44-369e-f435203dd1e3-7df3335d {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72fd-d5ae729d {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae7315-d5ae729d {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae7333-d5ae729d {
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_4b57b011-29ee-9b3c-5c8b-d36279807672-79807661 {
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c21-e5107b17 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-e5107b17 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c47-e5107b17 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c58-e5107b17 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_1dcb1f43-696e-f1be-9ea9-b5cd9927494a-06d37ac5 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-edd386ee-3c20-d870-e1e8-75cded529da3-06d37ac5 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_235391f1-e38b-d94b-4400-3933ad6b839c-06d37ac5 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_3d07fece-c4a0-3a64-3633-e07d04577e87-06d37ac5 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-bf673d4d-31f0-6b37-622d-97fd83e57e25-06d37ac5 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-aa76326e-26bb-2485-2a41-193dd390c27b-acdc9d04 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_6e9a4e63-bedc-3e66-94f5-8713244c2f2c-acdc9d04 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_74a920a8-4396-1422-dc72-17f42ba22f6d-6791a3b0 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_74a920a8-4396-1422-dc72-17f42ba22f6f-6791a3b0 {
    -ms-grid-column-span: 2;
    grid-column-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_74a920a8-4396-1422-dc72-17f42ba22f87-6791a3b0 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c21-bbfcced8 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-bbfcced8 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c47-bbfcced8 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-828706ac {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_44cb7e81-a9e1-73b5-a5d7-f2bb889fc191-828706ac {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-16894e51 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_44cb7e81-a9e1-73b5-a5d7-f2bb889fc191-16894e51 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-ba661f90 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_44cb7e81-a9e1-73b5-a5d7-f2bb889fc191-ba661f90 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_191c9af5-e868-c20f-dc68-0cd546abdb18-ba661f90 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c21-844c5ebf {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-844c5ebf {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c47-844c5ebf {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-28928fa0 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_38232dc5-0753-6847-3201-f96e48928390-28928fa0 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c6d8db5c-93c7-930c-22b2-8795a4dcb040-28928fa0 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-78e40e7f {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_38232dc5-0753-6847-3201-f96e48928390-78e40e7f {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c6d8db5c-93c7-930c-22b2-8795a4dcb040-78e40e7f {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5890df0f-3531-4c16-16b5-046d5b534c32-eb52c4f2 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_38232dc5-0753-6847-3201-f96e48928390-eb52c4f2 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c6d8db5c-93c7-930c-22b2-8795a4dcb040-eb52c4f2 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_771e707c-a588-52d6-274c-6a481b9315a9-7df3335d {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_771e707c-a588-52d6-274c-6a481b9315c4-7df3335d {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_771e707c-a588-52d6-274c-6a481b9315df-7df3335d {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72a0-d5ae729d {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72ba-d5ae729d {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72eb-d5ae729d {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae72fd-d5ae729d {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae7315-d5ae729d {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae7333-d5ae729d {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_9557f283-b4a5-a6e0-1a5d-a5b384238310-06d37ac5 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_1f9e6468-8df4-267d-41ac-b9c5265af50a-06d37ac5 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_7f47237c-c84b-80bc-93e4-58ddf91fea1d-06d37ac5 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_5214d4ac-caad-678a-40c0-18f70db7daf3-c6907912 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_33326e86-17e6-9aeb-874b-42ba635e44bd-c6907912 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_6ed77934-d6f9-d661-d814-f46cb9d151e5-c6907912 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-f937bc60-2d28-cf18-b39c-7e2a2e577ce6-c6907912 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-aa76326e-26bb-2485-2a41-193dd390c27b-34d7f279 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-fd3d8cad-a6f4-afe4-d14f-250e1f0228fd-34d7f279 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_71b72d8c-9fa5-5b68-0021-46b73201fed0-34d7f279 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-f18a5a31-0a61-61c6-6423-7a338b683cb7-34d7f279 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-ecd30889-fbfc-0cc8-b864-d575cf6bb150-34d7f279 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_4861038b-0f4d-9833-32e7-02ba424aa795-34d7f279 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-aa76326e-26bb-2485-2a41-193dd390c27b-acdc9d04 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #w-node-_6e9a4e63-bedc-3e66-94f5-8713244c2f2c-acdc9d04 {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_6d291be4-cdd1-a3aa-cebe-cd06c556520a-308e25f3 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_6d291be4-cdd1-a3aa-cebe-cd06c5565225-308e25f3 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_6d291be4-cdd1-a3aa-cebe-cd06c5565240-308e25f3 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_0a724e06-09c9-eb69-b091-b8f3f4a232ab-308e25f3 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_8884c69f-fc52-4c43-a57f-54f2b9fa9303-d85cb121 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-cc4074d1-9e37-4883-9733-1cb9ee7bc87c-d85cb121 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_74a920a8-4396-1422-dc72-17f42ba22f6d-6791a3b0 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_74a920a8-4396-1422-dc72-17f42ba22f87-6791a3b0 {
    -ms-grid-column-align: center;
    justify-self: center;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_3a3c82b9-5ffe-cf3d-c55d-2f4a43862252-7df3335d {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_771e707c-a588-52d6-274c-6a481b9315ab-7df3335d {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_771e707c-a588-52d6-274c-6a481b9315c6-7df3335d {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_771e707c-a588-52d6-274c-6a481b9315e1-7df3335d {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_345510a4-ef9a-6ea9-d36a-be32d5ae734e-d5ae729d {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0af6094d-cc24-3e7f-0bfd-e6c30c34cb0d-0c34cab8 {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-_3e304650-2751-4095-b6e8-2c82ee088577-ee08856b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_33326e86-17e6-9aeb-874b-42ba635e44bd-c6907912 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_6ed77934-d6f9-d661-d814-f46cb9d151e5-c6907912 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_6dcc5fb8-b24b-55da-afd6-db6e58017ae7-c6907912 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-bd7c206c-f4b9-8943-86ee-422154da4a8d-c6907912 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_46377fd2-3a61-cacf-ca35-c127c2ef336e-c6907912 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-be355935-3e03-0887-e4d2-9cf892c37aa5-c6907912 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-f937bc60-2d28-cf18-b39c-7e2a2e577ce6-c6907912 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_6717d2c2-a759-734f-effe-6274e71a9ea0-c6907912 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-aa76326e-26bb-2485-2a41-193dd390c27b-34d7f279 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-fd3d8cad-a6f4-afe4-d14f-250e1f0228fd-34d7f279 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_71b72d8c-9fa5-5b68-0021-46b73201fed0-34d7f279 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-f18a5a31-0a61-61c6-6423-7a338b683cb7-34d7f279 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-ecd30889-fbfc-0cc8-b864-d575cf6bb150-34d7f279 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_4861038b-0f4d-9833-32e7-02ba424aa795-34d7f279 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-aa76326e-26bb-2485-2a41-193dd390c27b-acdc9d04 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_6e9a4e63-bedc-3e66-94f5-8713244c2f2c-acdc9d04 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  #w-node-_6d291be4-cdd1-a3aa-cebe-cd06c556520c-308e25f3 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_6d291be4-cdd1-a3aa-cebe-cd06c5565227-308e25f3 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_6d291be4-cdd1-a3aa-cebe-cd06c5565242-308e25f3 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_0a724e06-09c9-eb69-b091-b8f3f4a232ad-308e25f3 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_8884c69f-fc52-4c43-a57f-54f2b9fa9303-d85cb121 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_6d8e74e5-1f80-dc03-eed8-d88d568c2a58-568c2a53 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_74a920a8-4396-1422-dc72-17f42ba22f6d-6791a3b0 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-c678dc40-aedb-3e5d-6797-ef02f1645827-6791a3b0 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_9557f283-b4a5-a6e0-1a5d-a5b384238310-bbfcced8 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_9557f283-b4a5-a6e0-1a5d-a5b384238310-844c5ebf {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
}

.grid-voorwaarden{
    -ms-grid-columns: 1fr 3fr;
    grid-template-columns: 1fr 3fr;
    grid-row-gap: 0;
}
@media screen and (max-width: 767px){
    .grid-voorwaarden{
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }
}
.text-decoration-none{
    text-decoration:none;
}
:focus-visible,
input:focus-visible {
    outline: none;
}
