.align-self-center{
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}
.align-self-end{
    -webkit-align-self: end;
    -ms-flex-item-align: end;
    -ms-grid-row-align: end;
    align-self: end;
}
.d-none{
    display: none!important;
}
.d-inline-xs-none{
    display: inline;
}
.d-xs-none,
.d-sm-none{
    display: block;
}
.d-xs-block,
.d-sm-block{
    display: none;
}
.d-inline-flex{
    display: inline-flex;
}
.d-xs-inline{
    display: none;
}
@media screen and (max-width: 991px) {
    .d-sm-block{
        display: block;
    }
    .d-sm-none{
        display: none;
    }
}
@media screen and (min-width: 992px) {
    .d-block.d-lg-none{
        display:none;
    }
}
@media screen and (max-width: 479px) {
    .nav-link-title.white {
        color: #0c0f21;
    }
    .d-inline-xs-none,
    .d-xs-none{
        display: none;
    }
    .d-xs-block{
        display: block;
    }
    .d-xs-inline{
        display: inline;
    }
    .mobile-center{
        text-align: center;
    }
    .mobile-100{
        width:100%;
    }
}
.text-left{
    text-align: left;
}
.text-center{
    text-align: center;
}
.mt-10{
    margin-top: 10px;
}
.mt-15{
    margin-top: 15px;
}
.mt-20{
    margin-top: 20px;
}
.dropdown-form.error-input,
input.placeholder-tekst.error-input,
.error-input,
.emptySpecificatie{
    border: 1px solid #c92727!important;
}

.houtsoort-grid .houtsoort{
    display: none;
}
.scrollCalculator{
    position: absolute;
    top: -105px;
}
.w-100{
    width: 100%;
}
.center-mini-heading._2 {
    margin-top: 8px;
}
.my-0,
.mb-0{
    margin-bottom: 0!important;
}
.my-0,
.mt-0{
    margin-top: 0!important;
}
.py-0,
.pt-0{
    padding-top:0!important;
}
.py-0,
.pb-0{
    padding-bottom:0!important;
}
.mx-0,
.ml-0{
    margin-left: 0!important;
}
.mx-0,
.mr-0{
    margin-right: 0!important;
}
.mx-5,
.mr-5 {
    margin-right: 5px!important;
}
.mx-5,
.ml-5 {
    margin-left: 5px!important;
}
.mx-20,
.mr-20{
    margin-right: 20px!important;
}
.mx-20,
.ml-20{
    margin-left: 20px!important;
}
.mb-40{
    margin-bottom: 40px!important;
}
.pb-0{
    padding-bottom:0!important;
}
.extra-info-radio{
    display: none;
}
.dropzone{
    position: relative;
}
.dropzone .dz-message{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}
.d-block{
    display: block;
}
.w-form-fail.noPlint,
.w-form-fail.noSpecificatie{
    margin-bottom: 14px;
}
[data-scrollTo]{
    cursor: pointer;
}
.pl-0{
    padding-left: 0!important;
}
.font-weight-bold{
    font-weight: bold;
}
.allColors.menu-text,
.color .menu-text{
    font-weight: normal;
    cursor: pointer;
}
.allColors.menu-text.font-weight-bold,
.color .menu-text.font-weight-bold{
    font-weight: bold;
}
.dropzone .dz-preview a.dz-remove, .dropzone-previews .dz-preview a.dz-remove {
    background-color: #fafafa;
    border-radius: 2px;
    border: 1px solid #eeeeee;
    text-align: center;
    color: #999999;
    cursor: pointer;
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
}
.dropzone .dz-preview:hover a.dz-remove, .dropzone-previews .dz-preview:hover a.dz-remove {
    display: block;
}
.dropzone .dz-preview .dz-progress{
    opacity: 0!important;
}
.overzicht-mini-heading .edit-icon {
    width: 18px;
    height: 18px;
    opacity: 0.15;
}
.arrow-button{
    align-items: center;
}
.blog-post-wrapper h5{
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}
.blog-post-wrapper h5:first-of-type{
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
}
.mw-100{
    max-width: 100%!important;
}
.dropdown-form{
    background-color: #ffffff;
}
.w-layout-grid.offerte-grid-full{
    grid-template-columns: 1fr;
}
@media screen and (max-width: 767px){
    .order-xs-2{
        order: 2;
    }
}
.webshop .dropdown-list-assortiment .w-layout-grid{
    overflow: auto;
    max-height: ~"calc(100vh - 119px)";
}
.nav-menu .w-layout-grid{
    overflow: auto;
    max-height: ~"calc(100vh - 171px)";
}
.nav-menu .w-layout-grid{
    min-height: 254px;
}
@media(max-width: 767px){
    .nav-menu .w-layout-grid{
        min-height: unset;
    }
}

@media screen and (max-width: 767px){
    .veelgestelde-vragen-main-grid .faq-main-item-wrapper{
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 5;
        grid-column-start: span 5;
        -ms-grid-column-span: 5;
        grid-column-end: span 5;
    }
}
@media screen and (max-width: 525px){
    .veelgestelde-vragen-main-grid .faq-main-item-wrapper{
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 5;
        grid-column-start: span 5;
        -ms-grid-column-span: 5;
        grid-column-end: span 5;
    }
}
.webshop-block{
    display: flex;
    flex-direction: column;
}
.webshop-block .before-block-top{
    flex: 1 1 auto;
}
.webshop-block .before-block-top .combideal-button-wrapper{
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
}
@media screen and (max-width: 1009px){
    .br993{
        display: none;
    }
}
.no-br408{
    display: none;
}
@media screen and (max-width: 424px){
    .br408{
        display: none;
    }
    .no-br408{
        display: block;
    }
}
.inputAddon{
    display: flex;
    align-items: center;
}
.inputAddon span{
    height: 43px;
    padding-left: 14px;
    padding-right: 14px;
    background-color: #d8d8d8;
    display: flex;
    align-items: center;
}
.inputAddon .form-item{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 0 0 0 16px;
}
.specificeren ul,

.specificeren li{
    list-style-type: none;
    padding-left: 0;
}
.specificeren li a{
    text-decoration: none;
    color: #0a0f16;
}
.specificeren ul ul{
    padding-left: 40px;
}
.icon-wrapper-mini span{
    color: #981c1f;
    font-size: 28px;
    height: auto;
}
.usp-block.flex{
    display: flex;
}
.usp-block.flex .icon-wrapper-mini {
    margin-right: 15px;
}
.usp-section .large-content-wrapper.block{
    margin-bottom: 25px;
}
.usp-section .large-content-wrapper.block._final{
    margin-bottom: 75px;
}
.instructie-grid {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    -ms-grid-columns: 0.5fr 0.5fr;
    grid-template-columns: 0.5fr 0.5fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
textarea.placeholder-tekst {
    display: flex;
    width: 100%;
    min-height: 84px;
    padding: 0px 12px 0px 16px;
    justify-content: flex-start;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #d8d8d8;
    border-radius: 2px;
    line-height: 30px;
}
textarea.placeholder-tekst._2{
    line-height: 18px;
    padding-top: 12px;
    font-size:14px;
}
.placeholder-tekst.typeFile{
    padding-top: 6px;
    margin-top: 10px;
}
.double-column{
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
}

.advies-popup-wrapper {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 99999;
    display: none;
    width: 100vw;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 20, 39, 0.75);
}
.large-image-text {
    position: relative;
    z-index: 100;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 35px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: -3px 18px 50px -14px rgba(0, 0, 0, 0.14);
}
.large-image-text._2 {
    margin-bottom: 69px;
}
.cross-close {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    margin-top: 50px;
    margin-right: 35px;
}
.popup-sluiten-text {
    display: inline-block;
    margin-right: 7px;
    font-family: Poppins, sans-serif;
    color: #001427;
    font-size: 12px;
}
.login-heading {
    margin-top: 0px;
    margin-bottom: 2px;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
}
.login-block-wrapper {
    height: 76px;
    margin-bottom: 0px;
}
.login-grid-page {
    margin-bottom: 13px;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.basic-section-heading-item {
    margin-top: 0px;
    font-family: Poppins, sans-serif;
    color: #000;
    font-size: 33px;
    line-height: 40px;
    font-weight: 600;
}

.icon-button-green {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 43px;
    margin-left: 0px;
    padding: 6px 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    background-color: #00b97c;
    text-decoration: none;
}
.icon-button-green:hover {
    background-color: #00b97c;
}
.button-right-text {
    position: relative;
    top: 1px;
    display: inline-block;
    margin-right: 4px;
    margin-left: 0px;
    font-family: Poppins, sans-serif;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
}
.usp-heading-wrapper.popup{
    margin-bottom: 20px;
}
.popup-cross {
    position: relative;
    top: -1px;
    width: 14px;
}
.cursor-pointer{
    cursor: pointer;
}
.hero-image-block-overlay .breadcrumb-wrapper{
    top: -62px;
}
@media screen and (min-width: 991.1px) {
    .overzicht-block-wrapper._1{
        order: 1;
    }
    .overzicht-block-wrapper._2{
        order: 2;
    }
    .overzicht-block-wrapper._3{
        order: 3;
    }
    .overzicht-block-wrapper._4{
        order: 4;
    }
}
@media screen and (max-width: 991px) {
    .kortingscode-popup-wrapper {
        padding: 8px 18px;
    }
    .button-right-text {
        display: inline;
    }
    .category-hero-new{
        text-align: center;
    }
}
@media screen and (max-width: 767px) {
    .basic-section-heading-item {
        font-size: 22px;
    }
    .button-right-text {
        font-size: 12px;
    }
    .breadcrumb-wrapper{
        display: none;
    }
    .intro-section.nav-block{
        padding-bottom: 75px;
    }
}
.menu-mobile{
    display: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.orange-block.top-menu{
    margin-top: 12px;
    padding: 10px;
}
.orange-block.top-menu p{
    font-size: 14px;
    line-height: 18px;
}
.orange-block a{
    color: #ff9c1e;
}
@media screen and (max-width: 479px) {
    .large-image-text {
        padding: 20px;
    }
    .cross-close {
        position: relative;
        margin-top: 0px;
        margin-right: 0px;
    }

    .button-right-text {
        font-size: 11px;
    }

    .offerte-grid-new {
        grid-template-columns: 1fr!important;
    }

    .offerte-label {
        display: none;
    }

    .hero-image-block-overlay {
        padding-bottom: 30px;

    }
    .menu-mobile{
        display: inline-block;
        max-width: 100% ;
    }
    .webshop-dropdown-toggle{
        padding-top: 15px;
        /*display: none;*/
    }
    .webshop-dropdown-toggle .nav-link-title.white{
        color: #ffffff
    }
    .grid-2.personalize{
        -ms-grid-columns: 0.75fr 2fr;
        grid-template-columns: 0.75fr 2fr;
    }
    .orange-block.top-menu{
        font-size: 13px;
        line-height: 22px;
    }
}
.full-green-button-disabled {
    background-color: #26b46f !important;
}
.usp-bar.no-bg,
.large-content-section.no-bg{
    background-image: none;
}
.large-content-section._2{
    background-color:#ffffff;
}
.large-content-wrapper.final{
    margin-bottom: 0;
}
.large-woodstep-slider .arrow-left,
.large-woodstep-slider .arrow-right{
    display: block;
}
.text-bold{
    font-weight: 700;
}
.mt-50{
    margin-top: 50px;
}
.offerte-new.next-step{
    margin-bottom: -30px;
}

.offerte-new._2 .offerte-block-stap-2{
    position: relative;
}
.offerte-new._2 .offerte-block.d-xs-none{
    display: none;
}
.large-offerte-block:last-of-type {
    margin-bottom: 0;
}
.large-content-section.offerte{
    padding-top: 60px;
    padding-bottom: 60px;
}
.overzicht-block-wrapper{
    min-width: 0;
}
.upload-text-plus-icon{
    overflow: hidden;
    text-overflow: ellipsis;
}
.plint-link .w-inline-block{
    width: 100%;
}
textarea.form-item.large{
    max-width: 100%;
    height: auto;
    min-height: 150px;
    padding-top: 10px;
}
textarea.form-item.kto{
    max-width: 100%;
    height: auto;
    min-height: 100px;
    padding-top: 10px;
    margin-bottom: 20px;
    background-color: #f7f7f7;
}
.d-flex{
    display: flex;
}
.justify-content-between{
    justify-content: space-between;
}
.justify-content-start{
    justify-content: start!important;
}
.justify-content-center{
    justify-content: center!important;
}
.justify-content-end{
    justify-content: end!important;
}
.align-items-center{
    align-items: center;
}
.before-block-image-wrap img{
    width: 100%;
}
@media screen and (max-width: 370px){
    .top-bar-link-wrapper{
        padding: 6px 8px;
    }
}
.gegevens-grid.reparatie,
.gegevens-grid.legservice {
    grid-column-gap: 16px;
}
@media screen and (max-width: 767px){
    .gegevens-grid.reparatie > div{
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
    }
    .gegevens-grid.legservice > div{
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
    }
    .gegevens-hidden-grid{
        grid-template-columns: auto;
    }
    .gegevens-grid.reparatie > div.large-block{
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
    }
    .grid-triple-usp-large .double-column{
        -ms-grid-row-align: stretch;
        align-self: stretch;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
    }
}
button.icon-button-orange{
    color: #FFFFFF;
    width: 100%;
    font-weight: 600;
}
.total-form-mini-block.select{
    position: relative;
}
.icon.w-icon-dropdown-toggle{
    z-index: 901;
}
.footer-title{
    display: block;
    text-decoration: none;
}
.text-right{
    text-align: right;
}
.text-md-right{
    text-align: left;
}
.text-left{
    text-align: left;
}
.offerte-grid-full .usp-list {
    font-size: 12px;
    font-weight: normal;
    margin-right: 0;
    margin-bottom: 9px;
    padding-top: 0;
    padding-bottom: 0;
}
.content-grid.small-margin{
    margin-bottom: 45px;
}
.usp-heading-wrapper._2{
    margin-bottom: 32px;
}

.hero-section.hhw-actie .hero-image-block-overlay {
    padding-top: 156px;
    padding-bottom: 110px;
    background-color: transparent;
}
.hero-section.hhw-actie .hero-img-block-img {
    -o-object-position: 83% 82%;
    object-position: 83% 82%;
}

.hero-section.foto-actie .hero-image-block-overlay {
    padding-top: 94px;
    padding-bottom: 62px;
    background-color: transparent;
}

.hero-section.foto-actie .offerte-block {
    margin-top: 0;
}
.hero-section.foto-actie .small-hero-heading-wrapper {
    position: relative;
}
.foto-button{
    position: absolute;
    bottom: 20px;
    right: 0;
}
@media(max-width: 991px){
    .foto-button{
        position: absolute;
        bottom: 0px;
        right: 5%;
    }
}
@media(max-width: 705px){
    .foto-button{
        position: absolute;
        bottom: 0px;
        right: 0;
        margin-right: 0!important;
        padding: 10px!important;
    }
}
@media(max-width: 479px){
    .hero-section.hhw-actie .hero-image-block-overlay{
        padding-top: 60px;
        padding-bottom: 30px;
    }

    .hero-section.hhw-actie .hero-img-block-img {
        -o-object-position: 50% 82%;
        object-position: 50% 82%;
    }
}
.offerte-description.bold{
    font-weight: 500;
}
.actieVrijblijvend .small-paragraph{
    font-weight: 500;
    font-size: 13px;
}
.offerte-description.bold strong,
.actieVrijblijvend .small-paragraph strong {
    font-weight: 800;
}

.calculator .small-check{
    margin-right: 6px;
    margin-top: 0;
}
.hero-section.counter-section .offerte-block{
    margin-top: 0px;
}
.counter-section .hero-title-copy{
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
}
.counter-section .service-heading{
    font-size: 20px;
}
.counter-section .service-heading strong{
    font-weight: 800;
}
.counter-section .hero-image-block-overlay{
    /*background-color: transparent;*/
    /*background-color: #981915;*/
    background-color: rgba(152, 25, 21, 0.88);
    padding-top: 63px;
    padding-bottom: 67px;
}
.hero-section.hhw-actie .small-hero-heading-wrapper{
    margin-top: -35px;
}
.hero-section.hhw-actie .hero-title-copy{
    font-size: 46px;
    padding-left: 18px;
    padding-right: 18px;
    display:inline-block;
    font-weight: 500;
    background-color: #981915;
}
.hero-section.hhw-actie h2.hero-title-copy{
    font-weight: 700;
}
.hhw-logo{
    height: 100px;
    display: inline-block;
    margin-top: 40px;
}
.hhw-giftcard{
    position: absolute;
    right: 450px;
    width: 375px;
    top: 70px;

}
@media screen and (max-width: 991px){
    .hhw-giftcard {
        position: relative;
        right: 0;
        display: inline;
        width: 340px;
        top: 0;
    }
    .hero-section.hhw-actie .hero-title-copy{
        line-height: 60px;
    }
}
@media screen and (max-width: 767px){
    .hero-section.hhw-actie .hero-title-copy{
        font-size: 40px;
        line-height: 54px;
        font-weight: 500;
        padding-left: 11px;
        padding-right: 11px;
    }
    .hhw-giftcard {
        width: 300px;
        top: 20px;
    }
}
@media screen and (max-width: 525px){
    .hero-section.hhw-actie .hero-title-copy {
        font-size: 26px;
        line-height: 35px;
        font-weight: 500;
        padding-left: 11px;
        padding-right: 11px;
    }
    .hhw-logo {
        height: 50px;
        display: inline;
        margin-top: 40px;
    }
    .hhw-giftcard {
        position: relative;
        width: 220px;
        top: 0;
    }
}
.prijsBerekening{
    position: relative;
}
.christmas .prijsBerekening:after{
    content: url("/website/images/santa-hat.png");
    position: absolute;
    top: -38px;
    right: -33px;
    z-index: 30;
}
#counter{
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    margin-top: 20px;
}
#counter span{
    color: #ffffff;
}
#counter .clock{
    display: flex;
    flex: 1;
    margin-bottom: 10px;
}
#counter .clock span {
    font-variant-numeric: tabular-nums;
    color: #000000;
    background-color: #ffffff;
    padding: 20px 9px;
    font-weight: bold;
    font-size: 42px;
}
#counter .clock span.firstSpan{
    margin-right: 10px;
}
#counter .counter-block{
    text-align: center;
}
#counter .divider{
    color: #ffffff;
    font-size: 36px;
    padding: 0 10px;
    margin-bottom: 27px;
}
@media screen and (max-width: 525px){
    #counter .clock span {
        font-variant-numeric: tabular-nums;
        color: #000000;
        background-color: #ffffff;
        padding: 12px 5px;
        font-weight: bold;
        font-size: 28px;
    }
    #counter .clock span.firstSpan{
        margin-right: 5px;
    }
}
@media screen and (max-width: 479px){
    .counter-section .hero-title-copy {
        font-size: 27px;
        line-height: 30px;
        font-weight: 500;
    }
    .counter-section .service-heading {
        font-size: 16px;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    /*html, body{*/
    /*    overflow-x: hidden;*/
    /*}*/
    /*.counter-section .prijsBerekening:after{*/
    /*    content: '';*/
    /*}*/
}
.counter-section .image-section-overlay{
    background: transparent;
}
span.color-code {
    position: absolute;
    right: 0px;
    color: white;
    width: 100%;
    background: rgba(0,0,0,0.45);
    text-align: center;
    bottom: 0;
}
.hero-grid.no-calculator .small-hero-heading-wrapper{
    max-width: 630px;
    margin: 0 auto;
    text-align: center;
}
.hero-grid.no-calculator .small-hero-heading-wrapper.large{
    max-width: 662px;

}
.hero-grid.no-calculator .service-heading{
    margin-bottom: 20px;
}
.hero-grid.no-calculator{
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-row-gap: 30px;
}
.hero-grid.no-calculator .small-hero-button-wrapper{
    margin-top: 20px;
}

@media screen and (max-width: 991px){
    #counter{
        justify-content: space-between;
    }
    .hero-grid.no-calculator {
        -ms-grid-columns: 0.95fr 0.75fr;
        grid-template-columns: 0.95fr 0.75fr;
        -ms-grid-rows: auto;
        grid-template-rows: auto;
        grid-row-gap: 30px;
    }
    .hero-grid.no-calculator .small-hero-heading-wrapper{
        margin: 0;
    }
}
.hero-grid.no-calculator #counter{
    justify-content: center;
}
.center-usp{
    display: flex;
    justify-content: center;
}
.red-price-crossed{
    text-decoration: line-through;
    color: #981915;
    margin: 0 5px;
}
.calc-footer .green-bold{
    font-size: 13px;
}
.w-layout-grid.offerte-grid-full{
    grid-row-gap: 4px;
    margin-bottom: 8px;
}
.position-relative{
    position: relative;
}
.kerspakketBlock{
    width: 50%;
}
@media screen and (max-width: 991px){
    .kerstWrap{
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 525px){
    .kerspakketBlock{
        width: 100%;
    }
    .advies-popup-wrapper .basic-section-heading-item{
        font-size: 17px;
    }
}
span.link{
    text-decoration: underline;
    color: #1a0dab;
}
.advies-popup-wrapper .upload-field{
    min-height: 152px;
}
.advies-popup-wrapper .modal-advies-popup{
    max-height: 95vh;
    overflow: auto;
}
.font-weight-400{
    font-weight: 400;
}
.font-weight-500{
    font-weight: 500;
}
.font-weight-600{
    font-weight: 600;
}
.font-weight-700{
    font-weight: 700;
}
.triple-grid-mobile{
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 479px){
    .advies-popup-wrapper .login-heading{
        font-size: 13px;
    }
}
.whatsappButton,
.whatsappButton:hover{
    background: #ffffff!important;
    border: 2px solid #00b97c!important;
    color: #00b97c;
    padding: 8px 16px 7px;
}
.whatsappButton .top-bar-link-icon{
    margin-bottom: 1px;
}
.justify-self-end,
.whatsappButton-wrap{
    justify-self: end;
}

.reparatieTitle{
    padding-right: 75px;
}
@media screen and (max-width: 768px){
    .whatsappButton-wrap{
        justify-self: center;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .reparatieTitle{
        padding-right: 0;
    }
}
.flex-gap-10{
    gap: 10px;
}
.black-bg{
    background: #1e1e1e!important;
}

.opening-wrapper{
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    z-index: 9;
}
.opening-wrapper-block{
    background: #1e1e1e;
    position: relative;
    overflow: visible;
    width: 100%;
    border-radius: 2px;
    color: #ffffff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.opening-wrapper-block p{
    color: white;
}
.opening-wrapper-block h2{
    color: #f0d181;
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 25px;
}
#openingForm{
    background: #1e1e1e;
    z-index: 10;
    padding: 100px 50px;
    border: 2px solid white;
    text-align: center;
    width: 100%;
}
#openingForm .icon-button-orange{
    background-color: #27c97a;
}
.opening-border-bottom{
    border-bottom: 2px solid #f0d181;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.no-row-gap{
    grid-row-gap: 0;
}
.opening-logo{
    width: 341px;
    margin: 0 auto 25px;
    display: block;
    z-index: 15;
}
@media screen and (max-width: 479px) {
    #openingForm {
        padding: 25px 15px;
    }
    .opening-wrapper-block{
        padding-left: 10px;
        padding-right: 10px;
    }
}
.sitemap li {
    margin-top: 13px;
}
.w-icon-dropdown-toggle {
    pointer-events: none;
}
.block-heading-large {
    margin-top: 0px;
    margin-bottom: 14px;
    font-family: Poppins, sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
}
#availability{
    resize: vertical;
}
@media(max-width: 1200px){
    .cf1lHZ.cf2MAH:not(.cf3l36) {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
.mb-10{
    margin-bottom: 10px;
}
.p-0{
    padding: 0!important;
}

.red-overlay{
    background-color: rgba(152, 25, 21, 0.88);
}
.hero-title-copy._2{
    font-weight: 400;
}
.mt-6{
    margin-top: 6px;
}
.vacature-usp-block.align-top{
    align-items: start;
}
.mb-15{
    margin-bottom: 15px!important;
}
.mb-25{
    margin-bottom: 25px!important;
}
.mb-section{
    margin-bottom: 69px;
}
.white-space-wrap{
    white-space: pre-wrap;
}
.trustpilot-widget{
    margin-left: -4px;
    height: 20px;
    max-width: 260px;
}
.top-bar .trustpilot-widget{
    margin-left: -14px;
    height: 20px;
    max-width: 230px;
}

@media(max-width: 767px){
    .trustpilot-widget{
        margin-top: -16px;
    }
    .top-bar .trustpilot-widget{
        margin-top: 0px;
        margin-left: -23px;
    }
}

@media(max-width: 495px){
    .top-bar .trustpilot-widget{
        margin-top: -16px;
        max-width: 160px;
        margin-left: -23px;
    }
}
@media(max-width: 468px){
    .top-bar{
        padding: 12px 8px;
    }
}
.overflow-hidden{
    overflow: hidden;
}
.green-block.light{
    background-color: rgba(54, 255, 54, 0.47);
    border-color: rgba(64, 255, 64, 0.48);
}
.font-size-13{
    font-size: 13px;
}
.mt-5{
    margin-top: 5px;
}
.btn-green{
    background-color: #00b97c;
}

.btn-green:hover{
    background-color: #26b46f;
}
.hero-image-block-overlay.action-banner{
    background-color: transparent;
}
.hero-image-block-overlay.action-banner .small-hero-heading-wrapper.w-layout-grid {
    -ms-grid-columns: 1fr 1.5fr;
    grid-template-columns: 1fr 1.5fr;
}
.hero-image-block-overlay.action-banner .hero-grid{
    grid-template-columns: 1.2fr 0.75fr;
}
.hero-image-block-overlay.action-banner .hero-title-copy{
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
}
.action-korting .hero-title-copy{
    text-align: left;
}
.blackfriday-deals:after{
    content: url("/website/images/woodstep/black-friday-deals-confetti.png");
    position: absolute;
    top: -163px;
    left: -232px;
    z-index: 0;
}
.black-friday-title{
    padding: 10px 20px;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    display: inline-block;
    margin-bottom:20px;
    font-size:50px;
    line-height: 43px!important;
}
.black-friday-sale{
    background: #ff9c1e;
    color: #ffffff;
    font-weight: 700;
    border-radius: 3px;
    padding: 7px 10px;
    white-space: nowrap;
    display: inline-block;
    margin-top: 10px;
    line-height: 35px;
}
.black-friday-sub{
    font-size: 34px;
}
.z-index-3{
    z-index: 3;
}
.hero-image-block-overlay.black-friday{
    padding-top: 75px;
    padding-bottom: 75px;
}
.hero-image-block-overlay.black-friday .action-deals{
    padding-top:30px;
}
.hero-image-block-overlay.black-friday .offerte-block{
    margin-top: 0;
}
.hero-image-block-overlay.black-friday .hero-title-copy{
    line-height: 30px;
}
.action-korting{
    padding-right: 10px;
    z-index: 31;
}
.action-image{
    width: 150%;
    max-width: 200%;
    right: 0;
    position: absolute;
    top: ~"calc(50% - 20px)";
    transform: translateY(-50%);
}
.action-title{
    padding: 10px 20px;
    background: #ffffff;
    color: #000000;
    border-radius: 3px;
    display: inline-block;
    font-size:50px;
    line-height: 43px!important;
    margin-bottom: 10px;
}
.koningsweek-title{
    background: #ff9c1e;
    border: 3px solid #ffffff;
    color: #ffffff;
}
.action-sale{
    background: #ff9c1e;
    color: #ffffff;
    font-weight: 700;
    border-radius: 3px;
    padding: 7px 10px;
    white-space: nowrap;
    display: inline-block;
    margin-top: 0px;
    line-height: 26px;
    font-size:30px;
    margin-bottom:5px;
}
.koningsweek-sale {
    background: #000000;
    border: 3px solid #ffffff;
}
.action-sub{
    font-size: 34px;
    line-height: 42px!important;
    margin-bottom: 5px;
}
.koningsweek-sub{
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.46);
}
.z-index-3{
    z-index: 3;
}
.hero-image-block-overlay.action-overlay{
    padding-top: 75px;
    padding-bottom: 75px;
    /*background-color: rgba(0, 0, 0, 0.2);*/
}
.hero-image-block-overlay.koningsweek-overlay{
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: transparent;
}

/*.hero-image-block-overlay.action-overlay-friday .action-deals{*/
/*    padding-top:30px;*/
/*}*/
.hero-image-block-overlay.action-overlay .offerte-block{
    margin-top: 0;
}
.hero-image-block-overlay.action-overlay .hero-title-copy{
    line-height: 30px;
}
@media screen and (max-width: 1350px){

    .action-image{
        width: 100%;
        max-width: 100%;
        position: relative;
        top:auto;
        transform: none;
    }
}
.action-text{
    display: none;
}

.discount-semi-bold {
    margin-left: 5px;
    padding: 3px 6px;
    border-radius: 2px;
    background-color: rgba(253, 207, 9, 0.2);
    color: #f1a700;
    font-size: 15px;
    font-weight: 600;
}
.action-banner .small-hero-heading-wrapper{
    grid-column-gap: 0;
}
@media(max-width: 991px){
    .hero-image-block-overlay.action-banner .small-hero-heading-wrapper.w-layout-grid {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }
    .action-korting{
        padding-right: 10px;
        margin-bottom: 20px;
    }
    .action-korting .hero-title-copy{
        text-align: center;
    }
    .action-deals:after {
        display:none;
    }
    .action-image{
        display: none;
    }
    .hero-image-block-overlay.action-banner .hero-title-copy.action-text{
        display: block;
        font-weight: 600;
        font-size: 32px;
    }
}
.font-weight-900{
    font-weight: 900;
}
@media(max-width: 767px) {
    #w-node-_9557f283-b4a5-a6e0-1a5d-a5b384238310-bbfcced8.action-banner {
        -ms-grid-row-span: 2;
        grid-row-end: 2;
        -ms-grid-row: 2;
        grid-row-start: 2;
    }
}
@media screen and (max-width: 468px){
    .action-banner{
        overflow: hidden;
    }
}
.hero-section.kerst .hero-image-block-overlay{
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: transparent;
    border-bottom: 10px solid #981915;
}
.hero-section.kerst *:not(.basic-button){
    color: #000000!important;
    text-shadow: none!important;
}
.hero-section.kerst .hero-img-block-img {
    -o-object-position: 50% 0%;
    object-position: 50% 0%;
}
.hero-section.kerst .kerstkrans {
    position: absolute;
    width: 360px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 300;
    pointer-events: none;
}

.hero-section.kerst .hero-grid{
    max-width: 680px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #ffffff;
}
@media screen and (max-width: 479px) {
    .hero-section.kerst .hero-grid {
        padding: 30px 10px;
        background: white;
    }
    .hero-section.kerst .hero-grid .service-heading {
        line-height: 22px;
    }
    .hero-section.kerst .kerstkrans {
        width: 250px;
        bottom: -189px
    }
    .hero-section.kerst .hero-image-block-overlay{
        padding-top: 70px;
        padding-bottom: 70px;
    }
}
@media screen and (max-width: 767px) {
    .nofam-footer {
        display: block;
        margin-bottom: 46px;
    }
}
@media screen and (min-width: 767.1px){
    .nofam-footer{
        position: absolute;
        bottom: 20px;
    }
    .nofam-footer img{
        max-width: none;
    }
}


.counter-section .hero-image-block-overlay.blue {
    background: rgba(0,20,39,0.85);
}

h1.hero-title-blue {
    font-size: 34px;
    line-height: 44px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 6px;
    font-family: Poppins, sans-serif;
    color: #fff;
}

span.hero-title-bigger {
    font-size: 50px;
    font-weight: 700;
}

span.text-span-price {
    background: #ff9c1e;
    padding: 3px 18px;
    border-radius: 3px;
    margin-left: 5px;
    color: #FFF;
    font-weight: 600;
    white-space: nowrap;
}

.counter-section .hero-image-block-overlay.blue .countDown {
    margin-top: 20px;
}

.counter-section .hero-image-block-overlay.blue .countDown #counter .clock span {
    font-size: 32px;
    padding: 15px 9px;
}
@media screen and (max-width: 1200px){
    h1.hero-title-blue {
        font-size: 26px;
        line-height: 38px;
        text-align:left;
    }
}
@media screen and (max-width: 525px){
    .counter-section .hero-image-block-overlay.blue .countDown #counter .clock span {
        font-size: 28px;
        padding: 12px 5px;
    }
    h1.hero-title-blue{
        font-size: 25px;
        line-height: 40px;
        text-align:left;
    }
    span.hero-title-bigger{
        font-size: 36px;
    }
    #counter{
        justify-content: space-between;
    }
}
/* customizable snowflake styling */
.snowflake {
    color: #fff;
    font-size: 1em;
    font-family: Arial, sans-serif;
    text-shadow: 0 0 5px #000;
}

@-webkit-keyframes snowflakes-fall {
    0% {
        top: -10%
    }
    100% {
        top: 100%
    }
}

@-webkit-keyframes snowflakes-shake {
    0%, 100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px)
    }
}

@keyframes snowflakes-fall {
    0% {
        top: -10%
    }
    100% {
        top: 100%
    }
}

@keyframes snowflakes-shake {
    0%, 100% {
        transform: translateX(0)
    }
    50% {
        transform: translateX(80px)
    }
}

.snowflake {
    position: fixed;
    top: -10%;
    z-index: 9999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    -webkit-animation-name: snowflakes-fall, snowflakes-shake;
    -webkit-animation-duration: 10s, 3s;
    -webkit-animation-timing-function: linear, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    -webkit-animation-play-state: running, running;
    animation-name: snowflakes-fall, snowflakes-shake;
    animation-duration: 10s, 3s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: 1, infinite;
    animation-play-state: running, running
}

.snowflake:nth-of-type(0) {
    left: 1%;
    -webkit-animation-delay: 0s, 0s;
    animation-delay: 0s, 0s
}

.snowflake:nth-of-type(1) {
    left: 10%;
    -webkit-animation-delay: 1s, 1s;
    animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
    left: 20%;
    -webkit-animation-delay: 6s, .5s;
    animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
    left: 30%;
    -webkit-animation-delay: 4s, 2s;
    animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
    left: 40%;
    -webkit-animation-delay: 2s, 2s;
    animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
    left: 50%;
    -webkit-animation-delay: 8s, 3s;
    animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
    left: 60%;
    -webkit-animation-delay: 6s, 2s;
    animation-delay: 6s, 2s
}

.snowflake:nth-of-type(7) {
    left: 70%;
    -webkit-animation-delay: 2.5s, 1s;
    animation-delay: 2.5s, 1s
}

.snowflake:nth-of-type(8) {
    left: 80%;
    -webkit-animation-delay: 1s, 0s;
    animation-delay: 1s, 0s
}

.snowflake:nth-of-type(9) {
    left: 90%;
    -webkit-animation-delay: 3s, 1.5s;
    animation-delay: 3s, 1.5s
}

.snowflake:nth-of-type(10) {
    left: 25%;
    -webkit-animation-delay: 2s, 0s;
    animation-delay: 2s, 0s
}

.snowflake:nth-of-type(11) {
    left: 65%;
    -webkit-animation-delay: 4s, 2.5s;
    animation-delay: 4s, 2.5s
}
.newsletter .fake-checkbox{
    width:18px;
    height:18px;
}
.newsletter .fake-checkbox .choose-icon.small{
    width: 12px;
}

.question-answer.nieuwsbrief{
    z-index: 1000;
    margin-top: -139px;
    margin-left: 0;
}
@media screen and (max-width: 1200px){

    .question-answer.nieuwsbrief{
        margin-top: -139px;
        margin-left: -40px;
        width: 330px;
    }
}

.menu-icon-text {
    display: none;
}
@media screen and (max-width: 479px){
    .large-menu-grid{
        -ms-grid-columns: 2fr 2fr;
        grid-template-columns: 2fr 2fr;
    }
    .brand-logo{
        margin-right: 0;
        height: 50px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .menu-icon-text{
        display: inline;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        vertical-align: top;
    }

    .question-answer.nieuwsbrief{
        margin-top: -197px;
        width: 230px;
    }
}

.rate {
    float: left;
    padding: 0 10px;
    margin-left: -29px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    cursor:pointer;
    width: 80px;
    text-align: center;
}

.rate:not(:checked) > label:before {
    content: url('/website/images/google-star-grey.svg');
    width: 38px;
    display: block;
    margin: 0 auto;
}
.rate > input:checked ~ label:before {
    content: url('/website/images/google-star-selected.svg');
    width: 38px;
    display: block;
    margin: 0 auto;
}
.rate:not(:checked) > label:hover:before,
.rate:not(:checked) > label:hover ~ label:before,
.rate > input:checked + label:hover:before,
.rate > input:checked + label:hover ~ label:before,
.rate > input:checked ~ label:hover:before,
.rate > input:checked ~ label:hover ~ label:before,
.rate > label:hover ~ input:checked ~ label:before {
    content: url('/website/images/google-star.svg');
    width: 38px;
    display: block;
    margin: 0 auto;
}
.header-fixed,
.footer-fixed{
    position: fixed;
    width: 100%;
}
.footer-fixed{
    bottom: 0;
}
.h-100vh{
    min-height: 100vh;
}
#ktoForm .large-content-section{
    padding-top: 150px;
}
#ktoForm,
#ktoForm .div-block-16{
    position: relative;
}

.kto-steps{
    position: absolute;
    width: 100%;
    left: 150%;
}
.kto-steps .step-heading{
    max-width: 900px;
}
@media screen and (max-width: 1200px){
    .kto-steps .step-heading{
        max-width: 750px;
    }
    #ktoForm .large-content-section{
        padding-top: 100px;
    }
}
@media screen and (max-width: 991px){
    .kto-steps .step-heading{
        max-width: 540px;
    }
    #ktoForm .large-content-section{
        padding-top: 75px;
    }
    #ktoForm .div-block-16{
        position: unset;
    }
    #ktoForm .offerte-grid-item{
        position: relative;
    }
}
@media screen and (max-width: 479px){
    .kto-steps .step-heading{
        max-width: 100%;
    }
    #ktoForm .large-content-section{
        padding-top: 50px;
        background-image: none;
    }
    .grid-2.kto{
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        grid-gap: 0;
        grid-auto-columns: unset;
    }
    .grid-2.kto #w-node-_74a920a8-4396-1422-dc72-17f42ba22f6d-6791a3b0{
        margin: 0 auto;
    }
    .grid-2.kto .logo{
        width: 150px;
        height: 30px;
    }
    h1.step-heading {
        font-size: 25px;
    }
    .rate{
        margin-left: -8px;
        padding: 0;
    }
    .rate:not(:checked) > label{
        font-size: 12px;
        width: 60px;
    }

    .rate > input:checked ~ label:before,
    .rate:not(:checked) > label:before,
    .rate:not(:checked) > label:hover:before,
    .rate:not(:checked) > label:hover ~ label:before,
    .rate > input:checked + label:hover:before,
    .rate > input:checked + label:hover ~ label:before,
    .rate > input:checked ~ label:hover:before,
    .rate > input:checked ~ label:hover ~ label:before,
    .rate > label:hover ~ input:checked ~ label:before {
        width: 37px;
    }
    .orange-button.kto{
        width: 100%;
        /*margin-bottom: 30px;*/
    }
    #ktoForm .full-green-button-copy{
        width: 100%;
        /*margin-bottom:40px;*/
    }

    .kto-previous{
        /*position: absolute;*/
        order: 2;
        text-align: center;
        bottom: 0;
        left: 0;
        width: 100%;
        line-height: 39px;
        right: 0;
    }
    .flex-column-sm{
        flex-flow: column;
    }
}
.pull-right{
    float: right;
}
.kto-previous{
    line-height:39px;
}

.upload-field-small {
    display: -moz-inline-block;
    display: inline-block;
    width: 100%;
    min-height: 150px;
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px dashed #9bbae8;
    border-radius: 3px;
    background-color: #fcfcff;
    color: #001427;
    font-size: 11px;
    line-height:18px;
    text-decoration: none;
    cursor: pointer;
}
.blog-post-wrapper._2 {
    margin: 0;
    padding: 30px;
}
.flex-50{
    flex: 50%;
}
.flex-column{
    flex-flow: column;
}
.rating-error{
    margin-left: 20px;
    text-align: left;
}
@media screen and (max-width: 767px) {
    .star-ratings {
        flex-flow: column;
    }
}
@media screen and (max-width: 479px) {
    .rating-error{
        margin-left: 8px;
        text-align: center;
    }
    .rate{
        display: inline-block;
        float: unset;
    }
}
@media screen and (max-width: 352px) {
    .rating-error {
        margin-left: 0px;
    }

    .rate:not(:checked) > label {
        font-size: 11px;
        width: 50px;
    }
}

.vacature-parketteur{
    position: absolute;
    width: 289px;
    right: 63px;
    bottom: -131px;
}
@media(max-width: 991px) {
    .vacature-parketteur {
        display: none;
    }
}
label.input-label{
    margin-bottom: 0px;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
}

.total-form-label-block {
    align-items: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    display: grid;
}

@media(max-width: 479px){
    .total-form-label-block {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        display: grid;
    }
}
.vacature-block{
    background: #001427;
    color: #fff;
    padding: 40px;
    margin: 30px 0 0 0;
    position: relative;
    overflow: hidden;
}

.vacature-block img{
    color: #FFF;
    filter: brightness(0%) invert(100%) grayscale(100%);
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.1;
    width: 250px;
}
.vacature-block.parketteur {
    padding: 100px 40px;
}
.vacature-block.parketteur img{
    color: #FFF;
    filter: unset;
    opacity: 1;
    right: 40px;
    width: 250px;
    bottom: auto;
    top: 15px;
    transform: unset;
}

.vacature-block h3{
    margin: 0 0 5px;
}
.vacature-block a{
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px dotted #fff;
}
@media screen and (max-width: 991px){
    .vacature-block.parketteur {
        padding: 40px;
    }
}

.product-block{
    padding:0;
    border-radius: 10px;
    border: 1px solid #cecece;
    margin-top:30px;
}
.product-block .product-data{
    padding:30px;
    flex-grow:1;
}
.product-block h3{
    margin-top:0;
    font-size: 28px;
}
.product-block .product-img{
    border-bottom: 1px solid #cecece;
    width: 100%;
    text-align: center;
}

.product-block .product-img img{
    max-width: 250px;
}

.product-block .product-block-button{
    padding:20px 0;
    background-color:#f58232;
    color:#ffffff;
    width:100%;
    font-size: 20px;
    text-align:center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.flex-direction-column{
    flex-direction: column;
}
.h-100{
    height:100%;
}
.gap-30{
    column-gap: 30px;
}

.list-unstyled{
    list-style-type: none;
    padding-left: 25px;
}
ul.list-unstyled li {
    position: relative;
}
ul.list-unstyled .top-bar-link-icon {
    width: 17px;
    left: -25px;
    position: absolute;
    top: 8px;
}

.list-large{
    line-height: 30px;
}
@media(max-width: 991px){
    .flex-wrap-md{
        flex-wrap: wrap;
    }
}
.category-image-overlay-new.vloerprotect-overlay {
    background: rgba(40, 51, 57, 0.88);
}
ol.large-paragraph{
    padding-left:17px;
}
.vloerprotect-wrapper{
    padding-right:60px;
}
@media(max-width: 767px){
    .vloerprotect-wrapper{
        padding-right:0px;
    }
}
.py-60{
    padding-top: 60px;
    padding-bottom:60px;
}
.footer .usp-wrapper{
    padding-top: 0;
    padding-bottom: 0;
}
.usp-list.no-style{
    background:none;
}

.usp-list.no-style .usp-icon {
    width: 50px;
    height: 30px;
    -o-object-fit: fill;
    object-fit: fill;
}
.full-image-section{
    /* The image used */
    background-image: url("/website/images/woodstep/parket-schuren-parallax.jpg");
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#vacatureVideo {
    width: 100%;
    object-fit: cover;
    object-position:top;
    max-height: 656px;
    width: 100%;
    max-width: 430px;
}
.showroom-text-block.video-block{
    max-width:602px;
}
.vacature-header-section{
    min-height:400px;
}
@media(max-width: 767px){
    .vacature-header-section{
        min-height:150px;
    }
    .vacature-usp{
        align-items:start;
    }
    .vacature-usp .usp-list.no-style{
        padding-left:0px;
        padding-top:5px;
        padding-bottom:5px;
    }
    .vacature-usp .usp-list.no-style .usp-icon{
        height:25px;
    }
    .vacature .grid-double{
        row-gap: 10px;
        column-gap: 10px;
    }
    .vacature .double-column{
        row-gap: 10px;
        column-gap: 10px;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
    }
}
.position-center{
    object-position:center;
}
@media(min-width: 768px){
    .text-md-center{
        text-align:center;
    }
}
.position-unset{
    position: unset;
}
.review-image{
    max-width: 315px;
    width: 100%;
}
.review-image img{
    position: absolute;
    bottom: 0;
    max-width:315px;
}
.action-label{
    border: 1px solid #ff9c1e;
    display: flex;
    border-radius: 5px;
    overflow:hidden;
}
.action-label-title {
    background: #ff9c1e;
    padding: 5px 12px;
    color: white;
    font-size:15px;
    font-weight: 600;
    display: inline-block;
    font-family: Poppins, 'sans-serif';
}
.action-label-price {
    background: #ffffff;
}

.action-label-price{
    white-space: nowrap;
    font-weight: 700;
    padding: 5px 0px 5px 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    display: flex;
    align-items: center;
}
.action-label-price sup{
    top: -9px;
    font-size: 22px;
    left: -7px;
}
.action-label-price sub{
    font-weight: 500;
    font-size: 10px;
    bottom: 0px;
    left: -14px;
}
.align-items-start{
    align-items: flex-start;
}


@media(max-width: 1200px){
    .action-label{
        flex-direction:column;
        background:#ffffff;
        align-items:center;
    }
    .action-label-price{
        padding: 15px 0px 15px 10px;
    }
    .hero-image-block-overlay.blue .container{
        padding: 0 16px;
    }

    .review-image{
        max-width: 280px;
        width: 100%;
    }
    .review-image img{
        position: absolute;
        bottom: 0;
        max-width:280px;
    }
}
@media(max-width: 1200px){
    .action-label{
        flex-direction:column;
        background:#ffffff;
        align-items:center;
    }
    .action-label-price{
        padding: 15px 0px 15px 10px;
    }
    .hero-image-block-overlay.blue .container{
        padding: 0 16px;
    }

    .review-image{
        max-width: 280px;
        width: 100%;
    }
    .review-image img{
        position: absolute;
        bottom: 0;
        max-width:280px;
    }
}
@media(max-width: 991px){
    .hero-grid.d-flex{
        flex-direction:column;
    }
    .small-hero-heading-wrapper{
        max-width: 420px;
        margin: 0 auto;
        width: 100%;
    }
    .small-hero-heading-wrapper > div{
        max-width: 275px;
    }
    .hero-image-block-overlay.black-friday .small-hero-heading-wrapper > div{
        max-width: 100%;
    }
    .blackfriday-deals{
        text-align: center;
    }

    .hero-image-block-overlay.action-overlay .small-hero-heading-wrapper > div{
        max-width: 100%;
    }
    .review-image{
        width: 100%;
        position: relative;
        max-width: 420px;
        margin: 0 auto;
    }
    .review-image img{
        position: absolute;
        right: 0;
        bottom: -34px;
        max-width: 185px;
    }
}
@media(max-width: 767px){
    .partners-grid .showroom-text-block{
        display: flex;
        justify-content: space-between;
    }
    .partners-grid .showroom-text-block img{
        width: 45%;
    }
    .offerte-block{
        min-width: auto;
    }
    .hero-image-block-overlay.blue .container{
        padding: 0px;
    }
    .action-label{
        padding-right: 5px;
        border-radius: 5px;
        display:flex;
        align-items:center;
    }
    .action-label-title {
        padding: 5px 5px;
        color: white;
        margin-right: 5px;
        font-weight: 600;
        display: inline-block;
        font-family: Poppins, 'sans-serif';
    }
}

@media(max-width: 767px){

    .action-label{
        padding-right: 0px;
        border-radius: 5px;
        display:flex;
        align-items:center;
    }
    .action-label-title {
        padding: 5px 5px;
        color: white;
        margin-right: 0px;
        font-weight: 600;
        display: inline-block;
        font-family: Poppins, 'sans-serif';
    }
}

@media(max-width: 479px){
    .action-label{
        padding-right: 0;
        text-align: center;
        flex-direction: column;
    }
    .action-label-title{
        width: 100%;
        margin-right: 0;
        font-size:14px;
    }
    h1.hero-title-blue{
        text-align:left;
        line-height: 30px;
        font-size:24px;
    }
    .hero-image-block-overlay,
    .hero-image-block-overlay.action-overlay{
        padding: 16px;
    }

    .hero-image-block-overlay.action-overlay .hero-title-copy{
        font-size: 26px;
        line-height: 38px!important;
    }
    .hero-image-block-overlay.action-overlay .action-title{
        font-size: 26px;
        line-height:32px!important;
        padding: 7px 10px;
    }
    .hero-image-block-overlay.action-overlay .action-sale{
        font-size: 23px;
    }
    .counter-section .hero-image-block-overlay{
        padding-top:30px;
    }
    .hero-grid{
        grid-row-gap: 15px;
    }
    .review-image img{
        right: -10px;
    }
    .action-label-price{
        font-size:30px;
    }
    .action-label-price sup{
        font-size:18px;
    }
    .action-label-price sub{
        font-size:10px;
        left: -13px;
    }
    .small-hero-heading-wrapper{
        order: 1;
    }
    .offerte-new{
        order: 2;
    }
}
.image-section-calculator .section-image{
    -o-object-position: 50% 72%;
    object-position: 50% 72%;
}
.default-banner-section .service-heading{
    font-family: Poppins, 'sans-serif';
    font-size:26px;
}
.default-banner-section .hero-image-block-overlay.black{
    background: rgba(0,0, 0, 0.50);
}
.default-banner-section .hero-img-block-img{
    -o-object-position: 50% 42%;
    object-position: 50% 42%;
}
@media screen and (max-width: 525px){
    .default-banner-section .service-heading{
        font-size:16px;
        margin-top: 0;
    }
    .default-banner-section h1.hero-title-blue{
        text-align: center
    }
    .default-banner-section .trustpilot-img{
        margin: 0 auto;
    }
    .default-banner-section .small-hero-heading-wrapper > div{
        max-width: none;
    }
    .text-xs-center{
        text-align: center;
    }
}
.color-block-popup{
    max-height: 90vh;
    overflow-y: auto;
}

.dark-title.font-32{
    font-size:32px;
}
._2-col-grid-auto {
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
}
@media screen and (max-width: 479px){
    .dark-title.font-32{
        font-size: 30px;
    }

    ._2-col-grid-auto {
        -ms-grid-rows: auto;
        grid-template-rows: auto;
        grid-template-columns: auto;
    }
}
.vacature-usp-text.wrap{
    white-space: normal;
}
.section-image-copy.auto-height{
    height: auto;
}
.google-reviews{
    /*top: 38px;*/
    /*right: 35px;*/
    /*position: absolute;*/
    width: 138px;
}
.usp-heading-wrapper-center.google-review{
    margin-bottom:20px;
}
.setOriginalPrice{
    font-size: 14px;
    margin-left: 0;
}
.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
